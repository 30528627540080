import React, {useState} from 'react';
import {Box, Grid} from "@mui/material";
import TextArea from "antd/es/input/TextArea";
import plusIcon from "../../../assets/images/whiteplus.png"
import shareIcon from "../../../assets/images/shareIcon.svg"
import {Tooltip} from "antd";

const IntroNlink = ({inputData, setInputData, deleteModal, setDeleteModal, setlinkRemoveNo, linkRemoveNo}) => {

    const [linkError, setLinkError] = useState("");

    function isValidURL(str) {
        const pattern = new RegExp(
            '^([a-zA-Z]+:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', // fragment locator
            'i'
        );
        if (pattern.test(str)) {
            return true
        } else {
            return false
        }
    }

    const redirectLink = (url) => {
        if (url) {
            window.open(url, '_blank');
        }
    }

    return (
        <>
            {/*<div className="main-inner">*/}
            {/*<div className="container">*/}
            {/*.........page section.........*/}

            <Box>

                <Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Box className="inner-gred page-Container-height">
                                <p>Introduction </p>
                                <Box className="border-gred">
                                    <TextArea
                                        value={inputData?.provider_introduction}
                                        onChange={(e) => {
                                            if (e.target.value.length <= 350) {
                                                setInputData({
                                                    ...inputData, provider_introduction: e.target.value,
                                                });
                                            }

                                        }}
                                        rows={8}
                                        cols={6}
                                        className="inner-gred"
                                    />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box className="inner-gred page-Container-height">
                                <Box className="d-flex justify-content-between align-items-center">
                                    <p>Website links</p>
                                    <span
                                        className="plus-icon float-end"
                                        onClick={() => {
                                            if (inputData?.website_link) {

                                                if (isValidURL(inputData?.website_link[inputData?.website_link?.length - 1])) {

                                                    setLinkError("")
                                                    setInputData({
                                                        ...inputData,
                                                        website_link: [...inputData?.website_link, ""],
                                                    });
                                                } else if (inputData?.website_link?.length == 0) {

                                                    setInputData({
                                                        ...inputData,
                                                        website_link: [...inputData?.website_link, ""],
                                                    });
                                                } else {

                                                    setLinkError("Please Enter Valid Link")
                                                }
                                            } else {
                                                setInputData({
                                                    ...inputData,
                                                    website_link: [...inputData?.website_link || "", ""],
                                                });
                                            }

                                        }}
                                    >
                                        <svg
                                            width="30"
                                            height="30"
                                            viewBox="0 0 30 30"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <g id="add">
                                                <g id="vuesax/outline/add">
                                                    <g id="add_2">
                                                        <path
                                                            id="Vector"
                                                            d="M22.5 15.9375H7.5C6.9875 15.9375 6.5625 15.5125 6.5625 15C6.5625 14.4875 6.9875 14.0625 7.5 14.0625H22.5C23.0125 14.0625 23.4375 14.4875 23.4375 15C23.4375 15.5125 23.0125 15.9375 22.5 15.9375Z"
                                                            fill="#292D32"
                                                        />
                                                        <path
                                                            id="Vector_2"
                                                            d="M15 23.4375C14.4875 23.4375 14.0625 23.0125 14.0625 22.5V7.5C14.0625 6.9875 14.4875 6.5625 15 6.5625C15.5125 6.5625 15.9375 6.9875 15.9375 7.5V22.5C15.9375 23.0125 15.5125 23.4375 15 23.4375Z"
                                                            fill="#292D32"
                                                        />
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </span>
                                </Box>
                                <Box>
                                    <Box style={{height: "225px", overflow: "auto"}}>
                                        {inputData?.website_link?.map((data, i) => {
                                            return (<>
                                                <div className="group-input mt-2 p-15 clearfix">
                                                    <div className="bg-white">
                                                        <input
                                                            type="url"
                                                            name=""
                                                            style={{
                                                                width: '88%',
                                                                whiteSpace: 'nowrap',
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis'
                                                            }}
                                                            value={inputData?.website_link[i]}
                                                            onChange={(e) => {
                                                                const newValue = inputData?.website_link?.map((value, j) => {
                                                                    if (i == j) {
                                                                        return e.target.value;
                                                                    } else {
                                                                        return value;
                                                                    }
                                                                });
                                                                setInputData({
                                                                    ...inputData, website_link: newValue,
                                                                });
                                                            }}
                                                            placeholder="Your Link here"
                                                        />
                                                    </div>


                                                    {/*    ================= link redirect =============== */}
                                                    <Tooltip placement="top" title={'Open Link'}>
                                                        <span
                                                            className="remove shareLink new-remove"
                                                            style={{
                                                                top: "16px",
                                                                right: "44px",
                                                                display: 'flex',
                                                                justifyContent: 'center'
                                                            }}
                                                            onClick={() => {
                                                                redirectLink(inputData?.website_link[i])
                                                            }}

                                                        >
                                                          <img src={shareIcon} alt="" style={{width: '80%'}}/>
                                                        </span>
                                                    </Tooltip>


                                                    {/*    ================= * =============== */}


                                                    {inputData?.website_link?.length > 1 && (<>
                                                        <Tooltip placement="top" title={'Delete Link'}>
                                                            <span
                                                                className="remove new-remove"
                                                                style={{top: "16px", right: "16px"}}
                                                                onClick={() => {
                                                                    setDeleteModal((pre) => ({
                                                                        ...pre, link: true,
                                                                    }));
                                                                    setlinkRemoveNo(i);
                                                                    setLinkError("")
                                                                }}
                                                                // onClick={() => {
                                                                //     setWebLinks({
                                                                //         ...webLinks,
                                                                //         webLinks: webLinks?.filter((datanew, j) => {
                                                                //             return i !== j;
                                                                //         }),
                                                                //     });
                                                                // }}
                                                            >
                                                            <svg
                                                                style={{position: "initial"}}
                                                                width="20"
                                                                height="20"
                                                                viewBox="0 0 20 20"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <g id="Icons">
                                                                    <path
                                                                        id="Vector"
                                                                        d="M12.3865 12.8635L7.61351 8.09054M7.61351 12.8635L12.3865 8.09054"
                                                                        stroke="#FF0000"
                                                                        strokeWidth="1.39212"
                                                                        stroke-linecap="round"
                                                                    ></path>
                                                                </g>
                                                            </svg>
                                                        </span>
                                                        </Tooltip>
                                                    </>)}
                                                </div>
                                            </>);
                                        })}
                                        <p className="t-14-bold" style={{color: "red"}}>{linkError}</p>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>

      
        </>
    )
}

export default IntroNlink;