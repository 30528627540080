import { useRoutes } from 'react-router-dom';

// project import
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import { Navigate } from '../../node_modules/react-router-dom/dist/index';
import Accesspage from 'pages/extra-pages/Accesspage';

// ==============================|| ROUTING RENDER ||============================== //
const FallbackRoute = {
   
    path: '*',
    element: <Navigate to="/accesspath" replace />,
  
};

// const BlankScreen = () => <div style={{display:"flex" ,justifyContent:"center",alignItems:"center" }}><img src={nopage}/></div>; // Blank screen
export default function ThemeRoutes() {
    return useRoutes([MainRoutes, LoginRoutes,FallbackRoute,]);
}
