import React, { useEffect } from 'react';
import nopage from '../assets/images/page404.svg';
import utilities from 'menu-items/utilities';
import menuItems from 'menu-items/index';
import { useNavigate } from '../../node_modules/react-router-dom/dist/index';
const Notfound = () => {
const navigate = useNavigate();
const userData = localStorage.getItem('adAnimaLogin');
  // useEffect(()=>{
  //   if (userData) {
  //     console.log(JSON.parse(userData));
  //     if (JSON.parse(userData).user_type === 'sub_admin') {
  //         const userRole = JSON.parse(userData).roles;

  //         for (const items of utilities.children) {
  //           console.log(items);
            
  //             if (userRole[items.subname]) {
                
  //               console.log(items.url);
                
  //                 navigate(`${items.url}`);
  //                 break;
  //             }
  //         }
      
  //     }else if(JSON.parse(userData).user_type === 'admin'){
  //       navigate('/dashboard');
  //     }
  // }
  // else{
  //   navigate('/login')
  // }
  // },[])
//   useEffect(()=>{
//     if (userData) {
//       console.log(JSON.parse(userData));
//       if (JSON.parse(userData).user_type === 'sub_admin') {
//           const userRole = JSON.parse(userData).roles;
// const tabsitems =[...menuItems.items[0].children,...menuItems.items[1].children];
//           for (const items of tabsitems) {
//             console.log(items);
            
//               if (userRole[items.subname]) {
                
//                 console.log(items.url);
                
//                   navigate(`${items.url}`);
//                   break;
//               }
//           }
      
//       }else if(JSON.parse(userData).user_type === 'admin'){
//         navigate('/dashboard');
//       }
//   }
//   else{
//     navigate('/login')
//   }
//   },[])
    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '5%' }}>
                <img src={nopage} />
            </div>
        </div>
    );
};

export default Notfound;
