import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import React from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { List, ListItemButton, ListItemIcon, ListItemText, Button } from '@mui/material';
import { Modal, Alert } from 'antd';

// assets
import { EyeOutlined, LogoutOutlined, UnlockOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { DomainContext } from 'App';
import { BASE_URL } from 'Configration';
import {  FormHelperText, Grid, Link, IconButton, InputAdornment, InputLabel, OutlinedInput, Stack } from '@mui/material';
import { toast } from 'react-toastify';

// ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //

const ProfileTab = ({ handleLogout }) => {
    const theme = useTheme();
    const token = JSON.parse(localStorage.getItem('adAnimaLogin'));
    const [showPassword, setShowPassword] = useState({
        oldpass: false,
        newpass: false,
        confirmpass: false, 
      });
      
      const handleClickShowPassword = (field) => {
        setShowPassword((prev) => ({ ...prev, [field]: !prev[field] }));
      };
      
      const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };
    const baseUrl = useContext(DomainContext);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const [ChangepasswordModal, setChangepasswordModal] = useState(false);

    const [password, setpassword] = useState({ confirmpass: '', newpass: '', oldpass: '' });
    const [passerr, setpasserr] = useState({ confirmpass: '', newpass: '', oldpass: '' });

    const changeInput = (e) => {
        const { name, value } = e.target;
        setpassword({ ...password, [name]: value });
    };
    const [popup, setpopup] = useState({ show: false, message: '', success: true });
 
    const changepassword = () => {
        let verify = true;
        let error = passerr;

        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&])[A-Za-z\d@.#$!%*?&]{8,15}$/;

        if (password.oldpass == '') {
            error = { ...error, oldpass: 'Please enter Password' };
            verify = false;
        } else if (!passwordRegex.test(password.oldpass)) {
            error = { 
                ...error, 
                oldpass: 'Password must be 8-15 characters and contain at least one uppercase letter, one lowercase letter, one digit, and one special character (@.#$!%*?&)' 
            };
            verify = false;
        }
         else {
            error = { ...error, oldpass: '' };
            verify = true;
        }

        if (password.newpass == '') {
            error = { ...error, newpass: 'Please enter New password' };
            verify = false;
        } else if (!passwordRegex.test(password.newpass)) {
            error = { 
                ...error, 
                newpass: 'Password must be 8-15 characters and contain at least one uppercase letter, one lowercase letter, one digit, and one special character (@.#$!%*?&)' 
            };
            verify = false;
        }else if (password.newpass == password.oldpass) {
            error = { ...error, newpass: '' };
            verify = false;
        } else {
            error = { ...error, newpass: '' };
            verify = true;
        }

        if (password.confirmpass == '') {
            error = { ...error, confirmpass: 'Please enter Confirm password' };
            verify = false;
        } else if (!passwordRegex.test(password.confirmpass)) {
            error = { 
                ...error, 
                confirmpass: 'Password must be 8-15 characters and contain at least one uppercase letter, one lowercase letter, one digit, and one special character (@.#$!%*?&)' 
            };
            verify = false;
        } else if (password.newpass != password.confirmpass) {
            error = { ...error, confirmpass: 'The new password and confirm password do not match. Please try again' };
            verify = false;
        } else {
            error = { ...error, confirmpass: '' };
            verify = true;
        }
        setpasserr(error);

        if (verify) {
            const changepass = new FormData();
            changepass.append('old_password', password.oldpass);
            changepass.append('new_password', password.confirmpass);
            try {
                fetch(`${BASE_URL}/admin/admin_change_password`, {
                    method: 'POST',
                    headers: { Authorization: `Bearer ${token.token}` },
                    body: changepass
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.success) {
                            // setpopup({ show: true, message: data.message, success: data.success });
                            toast.success(data.message);
                            setChangepasswordModal(false);
                        
                            setpassword({ confirmpass: '', newpass: '', oldpass: '' });
                        } else {
                            // setpopup({ show: true, message: data.message, success: data.success });
                            toast.error(data.message);
                            // if (data.message == 'The old password is incorrect. Please try again') {
                            //     setpasserr({ ...error, oldpass: 'Wrong Password' });
                            // } else if (
                            //     data.message == 'Your new password is similar to your current password, please try another password'
                            // ) {
                            //     setpasserr({ ...error, confirmpass: 'Your new password is similar to your current password.' });
                            // }
                        }
                    });
            } catch (err) {
                console.log(err);
            }
        } else {
        }
    };

    return (
        <>
            <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32, color: theme.palette.grey[500] } }}>
                <ListItemButton
                    selected={selectedIndex === 0}
                    style={{ backgroundColor: '#fff' }}
                    onClick={(event) => {
                        setChangepasswordModal(true);
                        // setfomikrander(['def']);
                    }}
                >
                    <ListItemIcon>
                        <UnlockOutlined />
                    </ListItemIcon>
                    <ListItemText primary="Change Password" />
                </ListItemButton>
                <ListItemButton selected={selectedIndex === 2} onClick={handleLogout}>
                    <ListItemIcon>
                        <LogoutOutlined />
                    </ListItemIcon>
                    <ListItemText primary="Logout" />
                </ListItemButton>
            </List>

            <Modal
                title="Change Password"
                centered
              
                open={ChangepasswordModal   
                    
                }
             
                onCancel={() => {
                 
                    setpassword({ confirmpass: '', newpass: '', oldpass: '' });
                    setChangepasswordModal(false);
                    setpasserr({ confirmpass: '', newpass: '', oldpass: '' });
                    setShowPassword({
                        oldpass: false,
                        newpass: false,
                        confirmpass: false})
                }}
                footer={''}
            >
                <form
                    onSubmit={(e) => {
                         e.preventDefault();
                        //  setChangepasswordModal(false)
                        changepassword();
                        setShowPassword({
                            oldpass: false,
                            newpass: false,
                            confirmpass: false})
                    }}
                >
                    <div className="mb-3">
                        <label htmlFor="old-password ">Old Password</label>
                       
                
                                    <OutlinedInput
                                        fullWidth
                                        // error={Boolean(touched.password && errors.password)}
                                         id="old-password"
                                         type={showPassword.oldpass ? 'text' : 'password'}
                                        value={password.oldpass}
                                         name="oldpass"
                                        
                                        onChange={changeInput}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => handleClickShowPassword('oldpass')}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                    style={{color: '#666666'}}
                                                    size="large"
                                                >
                                                    {showPassword.oldpass ? <EyeOutlined className="" /> : <EyeInvisibleOutlined className=""/>}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                         placeholder="Old Password"
                                    />
                        {passerr.oldpass != '' && (
                            <>
                                <p className="err mb-0" style={{ marginLeft: '0px', color: 'red' ,fontSize:"13px"}}>
                                    {passerr.oldpass}
                                </p>
                            </>
                        )}
                    </div>
                    <div className="mb-3">
                        <label htmlFor="new-password">New Password</label>
                        <OutlinedInput
                                        fullWidth
                                        // error={Boolean(touched.password && errors.password)}
                                      id="new-password"
                                        type={showPassword.newpass ? 'text' : 'password'}
                                  name="newpass"
                                        value={password.newpass}
                                        onChange={changeInput}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                        onClick={() => handleClickShowPassword('newpass')}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                    style={{color: '#666666'}}
                                                    size="large"
                                                >
                                                    {showPassword.newpass ? <EyeOutlined className="" /> : <EyeInvisibleOutlined className=""/>}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                          placeholder="New Password"
                                    />
                        
                        {passerr.newpass != '' && (
                            <>
                                <p className="err mb-0" style={{ marginLeft: '0px', color: 'red' ,fontSize:"13px"}}>
                                    {passerr.newpass}
                                </p>
                            </>
                        )}
                    </div>
                    <div className="mb-4">
                        <label htmlFor="confirm-password">Confirm Password</label>
                     
                         <OutlinedInput
                                        fullWidth
                                        // error={Boolean(touched.password && errors.password)}
                                       id="confirm-password"
                                        type={showPassword.confirmpass ? 'text' : 'password'}
                                   name="confirmpass"
                                  value={password.confirmpass}
                                        onChange={changeInput}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => handleClickShowPassword('confirmpass')}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                    style={{color: '#666666'}}
                                                    size="large"
                                                >
                                                    {showPassword.confirmpass ? <EyeOutlined className="" /> : <EyeInvisibleOutlined className=""/>}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        placeholder="Confirm Password"
                                    />
                        {passerr.confirmpass != '' && (
                            <>
                                <p className="err mb-0" style={{ marginLeft: '0px', color: 'red' ,fontSize:"13px"}}>
                                    {passerr.confirmpass}
                                </p>
                            </>
                        )}
                    </div>
                    <div className="loginbotton">
                        <Button disableElevation fullWidth size="large" type="submit" variant="contained" color="primary">
                            Change Password
                        </Button>
                    </div>
                </form>
            </Modal>
           
        </>
    );
};

ProfileTab.propTypes = {
    handleLogout: PropTypes.func
};

export default ProfileTab;
