import React, {useEffect, useState} from 'react';
import {Modal} from "react-bootstrap";
// import {sendHelpNsupport} from "../action/supportConstant";
import { useDispatch } from '../../../../node_modules/react-redux/es/exports';
import { BASE_URL } from 'Configration';

import { Alert } from 'antd';
const SupportPopup = ({open, setOpen,supportid,name}) => {
    const [loader, setLoader] = useState(false);
    const dispatch = useDispatch();
    console.log(name);
    const [categoryaddload, setcategoryaddload] = useState(true);
    const [massge, setMassage] = useState('');
    const [error, setError] = useState();
    const token = JSON.parse(localStorage.getItem('adAnimaLogin'));
const [popup, setpopup] = useState({ show: false, message: '', success: true });
useEffect(() => {
        if (popup.show) {
            setTimeout(() => {
                setpopup(false);
            }, 3000);
        }
    }, [popup]);

    const handleSubmit = () => {
        setLoader(true)
        // const payload = {
        //     id: userAuth?._id,
        //     massage: massge
        // }
        // dispatch(sendHelpNsupport(payload, (a) => {
        //     setLoader(a)
        // }, (a) => {
        //     setOpen(a)
        // }))
        const formdata= new FormData();
        formdata.append('help_reply', massge);
if(name == "support"){

    formdata.append('help_support_id', supportid);
}
if(name == "Report"){

    formdata.append('report_id', supportid);
}

     try {
                fetch(`${BASE_URL}/admin/support_reply_mail`, {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${token.token}`
                    },
                    body: formdata,
                   
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.success) {
                            setpopup({ show: true, message: data.message, success: data.success });
                         setMassage('');
                         setLoader(false);
                        setOpen(false);
                            
                           
                        }
                    });
            } catch (err) {
                console.log(err);
            }
    } 
    console.log(massge);
    useEffect(() => {
        if (massge!= '') {
            setcategoryaddload(false);
        } else {
            setcategoryaddload(true);
        }
    }, [massge]);   
    return (
        <>
        <div className="support_modal">
            <Modal
                centered
                show={open}
                backdrop="static"
                onHide={() => {
                    setOpen(false);
                    setMassage('');
                    setError("");
                }}
            >
                <div style={{maxWidth: '723px'}}>
                    <div className="d-flex justify-content-between align-items-center">
                        <p className="t-20-bold m-0 text-black">Provide Assistance</p>
                        <button
                            type="button"
                            className="close-modal"
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            <svg
                                width="50"
                                height="50"
                                viewBox="0 0 50 50"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle cx="25" cy="25" r="25" fill="#EEEEEE"/>
                                <path
                                    d="M25 38.4375C17.5875 38.4375 11.5625 32.4125 11.5625 25C11.5625 17.5875 17.5875 11.5625 25 11.5625C32.4125 11.5625 38.4375 17.5875 38.4375 25C38.4375 32.4125 32.4125 38.4375 25 38.4375ZM25 13.4375C18.625 13.4375 13.4375 18.625 13.4375 25C13.4375 31.375 18.625 36.5625 25 36.5625C31.375 36.5625 36.5625 31.375 36.5625 25C36.5625 18.625 31.375 13.4375 25 13.4375Z"
                                    fill="#363853"
                                />
                                <path
                                    d="M21.4627 29.4752C21.2252 29.4752 20.9877 29.3877 20.8002 29.2002C20.4377 28.8377 20.4377 28.2377 20.8002 27.8752L27.8752 20.8002C28.2377 20.4377 28.8377 20.4377 29.2002 20.8002C29.5627 21.1627 29.5627 21.7627 29.2002 22.1252L22.1252 29.2002C21.9502 29.3877 21.7002 29.4752 21.4627 29.4752Z"
                                    fill="#363853"
                                />
                                <path
                                    d="M28.5377 29.4752C28.3002 29.4752 28.0627 29.3877 27.8752 29.2002L20.8002 22.1252C20.4377 21.7627 20.4377 21.1627 20.8002 20.8002C21.1627 20.4377 21.7627 20.4377 22.1252 20.8002L29.2002 27.8752C29.5627 28.2377 29.5627 28.8377 29.2002 29.2002C29.0127 29.3877 28.7752 29.4752 28.5377 29.4752Z"
                                    fill="#363853"
                                />
                            </svg>
                        </button>
                    </div>
                    {/* <div className="mt-3"> */}
                        {/* <p className="m-0 t-15-bold">Esther, how can we help you?</p> */}
                        <div className="group-input mt-2  p-15 clearfix">
                        <textarea name="" id=""
                                  value={massge}
                                  onChange={(e) =>{
                                    const inputval = e.target.value;
                                    if (inputval.trim() == "" && inputval !== "") {
                                        setError('Spaces are not allowed.');
                                        return;}
                                    setError('');
                                    setMassage(inputval)}}
                                  style={{
                                      width: "100%",
                                    //   border: '1px dashed black',
                                    //   borderRadius: '5px',
                                      height: "120px",
                                      padding: "6px 10px"
                                  }} placeholder="Type your reply here..."/>

                                  </div>
                                  {error && <p style={{ color: 'red', fontSize: '12px' }} className='mt-1 mb-0'>{error}</p>}
                    {/* </div> */}
                    <div className="d-flex flex-wrap align-items-center justify-content-center">
                       { categoryaddload ? <button
                        disabled
                            type="submit"
                            style={{
                                maxWidth: "350px", height: "45px"
                            }}
                            // disabled={!stripe || !elements}
                            className="massageBtn mt-3"
                            // onClick={() => handleSubmit()}
                        >
                            Submit
                        </button>:<button
                            type="submit"
                            style={{
                                maxWidth: "350px", height: "45px"
                            }}
                            // disabled={!stripe || !elements}
                            className="massageBtn mt-3"
                            onClick={() =>{ handleSubmit()
                                setcategoryaddload(true);}
                            }
                        >
                            Submit
                        </button>
                       
                        }
                    </div>
                </div>
            </Modal>
        </div>
             {popup.show && (
                            <Alert
                                message={popup?.message}
                                type={popup?.success ? 'success' : 'error'}
                                showIcon
                                style={{ position: 'fixed', right: '15px', bottom: '30px' }}
                            />
                        )}
        </>
    )
}

export default SupportPopup;