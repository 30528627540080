import React, { useContext, useEffect, useState } from 'react';

import {
    CheckCircleFilled,
    CheckOutlined,
    CloseOutlined,
    DeleteOutlined,
    EditOutlined, EyeOutlined,
    PlusOutlined,
    SearchOutlined
} from '@ant-design/icons';
import { Checkbox, IconButton } from '@mui/material';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Alert, DatePicker, Divider, Empty, Popconfirm, Tooltip } from 'antd';
import { DomainContext, PadinationContext } from 'App';
import noimage from 'assets/images/usericon.png';
import { BASE_URL } from 'Configration';
import dayjs from "dayjs";
import { Link } from 'react-router-dom';
import { activeItem } from 'store/reducers/menu';
import { Pagination } from '../../../node_modules/@mui/material/index';
import { useDispatch } from '../../../node_modules/react-redux/es/exports';
import { useNavigate, useParams } from '../../../node_modules/react-router/dist/index';
import Loader from "../../components/Loader";
import ClaimSheetModel from "./ClaimSheetModel";
import { ThreeDots } from 'react-loader-spinner';
import { toast } from '../../../node_modules/react-toastify/dist/index';
let keyword = '';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',

    boxShadow: 24,
    p: 4
};

const {RangePicker} = DatePicker;

const ClaimUser = () => {
    const token = JSON.parse(localStorage.getItem('adAnimaLogin'));
    const baseUrl = useContext(DomainContext);
    const [breadcrumb, setbreadcrumb] = useState(JSON.parse(sessionStorage.getItem('breadcrumb')));
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const paginationcontext = useContext(PadinationContext);
    const [openAdd, setOpenAdd] = React.useState(false);
    const [isEdit, setIsEdit] = useState(false)
    const [editedData, setEditedData] = useState({})
    const handleOpen = () => setOpenAdd(true);
    const [file, setFile] = useState(null);
    const [isLoader, setIsLoader] = useState(true);
    const handleClose = () => {
        setIsEdit(false)
        setOpenAdd(false)
        setFile("")
    };
    const { number } = useParams();
    const [totalPage, settotalPage] = useState(1);
    const localusers = JSON.parse(localStorage.getItem('add_Claim_Users'));
    const [controller, setController] = useState(null);
    const [userdata, setuserdata] = useState(localusers ? localusers : []);
    const [pageuserdata, setpageuserdata] = useState(localusers ? localusers : []);
    const [searchchange, setsearchchange] = useState('');
    const [popup, setpopup] = useState({show: false, message: '', success: true});
    const getalldata = (startDate, endDate) => {

        if (token?.is_login && token?.is_login == true) {
            dispatch(activeItem({openItem: ['util-prePolution']}));
            const userList = new FormData();
        
            if (startDate) {
                userList.append('fromDate', new Date(startDate).toISOString());
                userList.append('toDate', new Date(endDate).toISOString());
            }
            userList.append("search",searchchange)
            {
                Number(number) ? userList.append('page', Number(number)) : userList.append('page', Number(1));
            }
            userList.append("limit",10);
            if (controller) {
                controller.abort();
            }
            const newController = new AbortController();
            setController(newController);
            try {
                fetch(`${BASE_URL}/claim_provider/get_all_claim_providers`, {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${token.token}`
                    },
                    body: userList,
                    signal: newController.signal
                })
                    .then((response) => response.json())
                    .then((data) => {
                        setIsLoader(false)
                        if (data.success) {
                            localStorage.setItem('add_Claim_Users', JSON.stringify(data.data));
                            setuserdata(data.data);
                       
                            const totalNumberOfData = data?.
                            total_number_of_data || 0; 
                            const totalPages = Math.ceil(totalNumberOfData / 10); 
                            settotalPage(totalPages);
                        }else if (data.statuscode == 101) {
                            localStorage.clear();
                            toast.error(data?.message);
                            window.location.reload();
                            // navigate('/login');
                        }
                    });
            } catch (err) {
                setIsLoader(false)
                console.log(err);
            }
        } else {
            setIsLoader(false)
            navigate('/login');
        }
    }





    useEffect(() => {
        if (popup.show) {
            setTimeout(() => {
                setpopup(false);
            }, 3000);
        }
    }, [popup]);

   
    useEffect(() => {
       
        getalldata();
       
         return () => {
             if (controller) {
                 controller.abort();
             }
         };
     }, [number,searchchange]);
    const breadcrumbManager = (a, b) => {
        let bread = [];
        let prepath = false;
        for (var i = 0; i < breadcrumb?.length; i++) {
            bread.push(breadcrumb[i]);
            if (breadcrumb[i].url == b) {
                prepath = true;
                break;
            }
        }
        if (prepath == false) {
            bread.push({name: a, url: b});
        }

        sessionStorage.setItem('breadcrumb', JSON.stringify(bread));
    };

    // ================== Delete User ==================

    const deleteUser = (a) => {
        const deleteuser = new FormData();
        deleteuser.append('user_id', a);
        try {
            fetch(`${BASE_URL}/claim_provider/delete_claim_account`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token.token}`
                },
                body: deleteuser
            })
                .then((response) => response.json())
                .then(async (data) => {
                    setpopup({show: true, message: data.message, success: data.success});
                    if (data.success) {
                        const val = localusers?.filter((e) => {
                            return e._id != a;
                        });
                        await setuserdata(val);
                        localStorage.setItem('add_Claim_Users', JSON.stringify(val));
                        // searchevent(searchchange, val)
                    }
                });
        } catch (err) {
            console.log(err);
        }
    };



    



 


    useEffect(() => {
        let bread = [];
        let prepath = false;
        for (var i = 0; i < breadcrumb?.length; i++) {
            bread.push(breadcrumb[i]);
            if (breadcrumb[i].url == window.location.pathname) {
                prepath = true;
                break;
            }
        }
        if (prepath == false) {
            setbreadcrumb(JSON.parse(sessionStorage.getItem('breadcrumb')));
        } else {
            setbreadcrumb(bread);
            sessionStorage.setItem('breadcrumb', JSON.stringify(bread));
        }
    }, []);

    const handleActiveUser = (a) => {
        const data = new FormData();
        data.append('user_id', a);

        try {
            fetch(`${BASE_URL}/claim_provider/activate_account`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token.token}`
                },
                body: data
            })
                .then((response) => response.json())
                .then(async (data) => {
                    setpopup({show: true, message: data.message, success: data.success});
                    const updatedData = data?.data[0];


                    const newData = [...userdata];
                    const dataIndex = newData.findIndex((item) => item._id === updatedData?._id);
                    if (dataIndex !== -1) {
                        newData[dataIndex] = updatedData;
                    }
                    await setuserdata(newData);
         
                 

                });
        } catch (err) {
            console.log(err);
        }
    }


    const handleInActiveUser = (a) => {
        const data = new FormData();
        data.append('user_id', a);

        try {
            fetch(`${BASE_URL}/claim_provider/deactivate_account`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token.token}`
                },
                body: data
            })
                .then((response) => response.json())
                .then(async (data) => {
                    setpopup({show: true, message: data.message, success: data.success});
                    const updatedData = data?.data[0];

               
                    const newData = [...localusers];
                    const dataIndex = newData.findIndex((item) => item._id === updatedData?._id);
                    if (dataIndex !== -1) {
                        newData[dataIndex] = updatedData;
                    }
                    await setuserdata(newData);
                 
                   

                });
        } catch (err) {
            console.log(err);
        }
    }


    const [selectedRows, setSelectedRows] = useState([]);

    const handleRowSelect = (rowData) => {
        const selectedIndex = selectedRows.indexOf(rowData._id);
        console.log(selectedIndex);
        
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedRows, rowData._id);
         
            
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedRows.slice(1));
        } else if (selectedIndex === selectedRows.length - 1) {
            newSelected = newSelected.concat(selectedRows.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedRows.slice(0, selectedIndex),
                selectedRows.slice(selectedIndex + 1)
            );
        }

        setSelectedRows(newSelected);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            // const newSelecteds = pageuserdata.map((data) => data._id); //--> only for one page
            const newSelecteds = userdata.map((data) => data._id);
            setSelectedRows(newSelecteds);

            return;
        }
        setSelectedRows([]);
    };

    const isSelected = (rowData) => selectedRows.indexOf(rowData?._id) !== -1;


    const handleDeleteBulk = () => {
        const data = new FormData();
        data.append('user_ids', JSON.stringify(selectedRows));

        try {
            fetch(`${BASE_URL}/claim_provider/delete_multiple_account`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token.token}`
                },
                body: data
            })
                .then((response) => response.json())
                .then((data) => {
                    setpopup({show: true, message: data.message, success: data.success});
                    const updatedData = data?.data;

                    // Update userdata in state
                    setuserdata(prevData => {
                        getalldata()
                        const newData = [...prevData];
                        const dataIndex = newData.findIndex((item) => item._id === updatedData?._id);

                        if (dataIndex !== -1) {
                            newData[dataIndex] = updatedData;

                          
                        }

                        return newData;
                    });
                });
        } catch (err) {
            console.log(err);
        }
    }


    const handleActivateBulk = () => {
        const data = new FormData();
        data.append('user_ids', JSON.stringify(selectedRows));

        try {
            fetch(`${BASE_URL}/claim_provider/activate_multiple_account`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token.token}`
                },
                body: data
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.message.includes("Email require for activation")) {
                        setpopup({show: true, message: data.message, success: false});
                    } else {
                        setpopup({show: true, message: data.message, success: data.success});
                    }
                    const updatedData = data?.data;

            
                    setuserdata(prevData => {
                        getalldata()
                        const newData = [...prevData];
                        const dataIndex = newData.findIndex((item) => item._id === updatedData?._id);

                        if (dataIndex !== -1) {
                            newData[dataIndex] = updatedData;

                        }

                        return newData;
                    });
                });
        } catch (err) {
            console.log(err);
        }
    }

    const handleDeActivateBulk = () => {
        const data = new FormData();
        data.append('user_ids', JSON.stringify(selectedRows));

        try {
            fetch(`${BASE_URL}/claim_provider/deactivate_multiple_account`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token.token}`
                },
                body: data
            })
                .then((response) => response.json())
                .then((data) => {
                    setpopup({show: true, message: data.message, success: data.success});
                    const updatedData = data?.data;

                
                    setuserdata(prevData => {
                        getalldata()
                        const newData = [...prevData];
                        const dataIndex = newData.findIndex((item) => item._id === updatedData?._id);

                        if (dataIndex !== -1) {
                            newData[dataIndex] = updatedData;

                           
                        }

                        return newData;
                    });
                });
        } catch (err) {
            console.log(err);
        }
    }


    const rangePresets = [
      
        {
            label: 'Last 30 Days',
            value: [dayjs().add(-30, 'd'), dayjs()],
        },
      
    ];

    
    const onRangeChange = (dates, dateStrings) => {
        if (dates) {
            console.log('From: ', dates[0]?.$d, ', to: ', dates[1]);
            console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);

            getalldata(dates[0]?.$d, dates[1]?.$d)
        } else {
            getalldata()
        }
    };




    return (
        <>
            <div className="breadcrumb d-flex align-items-center">
                <p style={{color: '#000000'}}>{'Pre-Polutation'}</p>
            </div>
            <div className="col-lg-12 d-flex flex-wrap align-items-center mb-3">
                <div className="col-lg-6 p-2 position-relative">
                   
                        <input
                            className="search-input"
                            placeholder="Search here"
                            value={searchchange}
                            onChange={(e) => {
                                setsearchchange(e.target.value);
                            
                                navigate(`/pre-polutation/1`);
                            }}
                        />
                        <div className="search-icon">
                            <SearchOutlined/>
                        </div>
                    {/*</div>*/}
                </div>
                <div className="me-2">
                    <RangePicker presets={rangePresets} onChange={onRangeChange} RangePicker/>
                </div>
          
                <div className="d-flex gap-2 align-items-center" style={{
                    marginLeft: 'auto',
                    marginRight: '20px',
                    display: "flex",
                    alignItems: "center",
             
                }}>
                    {selectedRows?.length ? <div>
                        <Tooltip placement="top" title={'Delete Selected Users'}>
                            <IconButton onClick={() => handleDeleteBulk()}
                                        style={{background: 'white', border: '1px solid #ededed', padding: '20px'}}>
                                <DeleteOutlined style={{color: "red"}}/>
                            </IconButton>
                        </Tooltip>
                    </div> : null}
                    {selectedRows?.length ? <div>
                        <Tooltip placement="top" title={'List Selected Users'}>
                            <IconButton onClick={() => handleActivateBulk()}
                                        style={{background: 'white', border: '1px solid #ededed', padding: '20px'}}>
                                <CheckOutlined style={{color: "green"}}/>
                            </IconButton>
                        </Tooltip>
                    </div> : null}
                    {selectedRows?.length ? <div>
                        <Tooltip placement="top" title={'Un-list Selected Users'}>
                            <IconButton onClick={() => handleDeActivateBulk()}
                                        style={{background: 'white', border: '1px solid #ededed', padding: '20px'}}>
                                <CloseOutlined style={{color: "orange"}}/>
                            </IconButton>
                        </Tooltip>
                    </div> : null}
                    <div style={{
                        marginLeft: 'auto',
                        marginRight: '20px',
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        // background: 'white', border: '1px solid #ededed',
                        // padding: '20px'
                        paddingLeft: '5px'
                    }}>
                      
                        <Button
                            size="large"
                            onClick={handleOpen}
                            className="d-flex align-items-center ms-auto bg-white add-cattegory-button"
                            type="primary"
                            ghost
                        >
                            <PlusOutlined/> <span className="ms-1">Import Excel File</span>
                        </Button>
                      
                    </div>
                </div>
               
            </div>
            <Divider orientation="left" className="my-2">
                Pre-Polutation
            </Divider>
            {isLoader?   
             <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              marginTop:"10%"
              }}><ThreeDots
             visible={true}
             height="80"
             width="80"
             color="#0dbcec"
             radius="9"
             ariaLabel="three-dots-loading"
             wrapperStyle={{color:"linear-gradient(180deg, #0dbcec, #ffc056)}"}}
             wrapperClass=""
             /> </div>
      :
            <div className="p-2">
                {userdata?.length > 0 ? (
                    <>


                        <TableContainer component={Paper} className="mb-4">
                            <Table sx={{minWidth: 650}} aria-label="simple table">
                                <TableHead>
                                    <TableRow className="bg-light-gray">
                                        <TableCell className="bh-line">
                                            <Checkbox
                                                indeterminate={
                                                    
                                                    selectedRows.length > 0 && selectedRows.length < userdata.length
                                                }
                                                
                                                checked={selectedRows.length === userdata.length}
                                                onChange={handleSelectAllClick}
                                            />
                                        </TableCell>
                                        <TableCell className="bh-line">Name</TableCell>
                                        <TableCell className="bh-line">Email</TableCell>
                                        <TableCell className="bh-line">Mobile No</TableCell>
                                        <TableCell className="bh-line">Description</TableCell>
                                        <TableCell className="bh-line">Location</TableCell>
                                        <TableCell align="center" style={{minWidth: '150px'}}>
                                            Action
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {userdata?.length > 0  && userdata?.map((data, i) => (
                                        <TableRow
                                            key={data?._id}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                            selected={isSelected(data)}
                        
                                        >
                                            <TableCell className="br-line">
                                                <Checkbox
                                                    checked={isSelected(data)}
                                                    onChange={() => handleRowSelect(data)}
                                                />
                                            </TableCell>
                                            <TableCell className="br-line">

                                                <div className="d-flex align-items-center">
                                                    <div className="position-relative">
                                                        <div className="profile-img-table me-3">
                                                            <img
                                                                src={data?.profile_picture != undefined ? `${data?.profile_picture}` : noimage}
                                                                onError={({currentTarget}) => {
                                                                    currentTarget.onerror = null; 
                                                                    currentTarget.src = noimage;
                                                                }}
                                                                alt="profile"
                                                                width="100%"
                                                                className="object-fit"
                                                            />
                                                        </div>
                                                        {data?.is_verified && (
                                                            <>
                                                                <div className="verified-user">
                                                                    <CheckCircleFilled/>
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                    <div className="table-p">
                                                        <p className="m-0">{data?.name || "-"}</p>
                                                    </div>
                                                </div>
                                            </TableCell>
                                            <TableCell
                                                className="br-line">{data?.email_address == "null" ? '-' : (data?.email_address || '-')}</TableCell>
                                            <TableCell
                                                className="br-line">{data?.mobile_number ? `+${(data?.country_code ? data?.country_code : "") + data?.mobile_number}` : '-'}</TableCell>
                                         
                                            <TableCell className="br-line">
                                                {data?.provider_services?.map((ele) => {
                                                    return (<>
                                                        {ele == "Other" ? (<>
                                                            <span>{data?.other_desc ? `${data?.other_desc}, ` : null} </span>
                                                        </>) : (<>
                                                            <span>{ele}, </span>
                                                        </>)}
                                                    </>);
                                                })}
                                            </TableCell>
                                            <TableCell className="br-line">{data?.address || '-'}</TableCell>
                                            <TableCell align="center">
                                                <div className='d-flex '>
                                                    <Tooltip placement="top" title={'View User'}>
                                                        <Link
                                                            to={`/pre-polutation-user/${data?._id}`}
                                                            onClick={() => breadcrumbManager(data?.name, `/pre-polutation-user/${data?._id}`)}
                                                        >
                                                            <div
                                                                className="me-2 action-button bg-white"
                                                                style={{border: '1px solid #1677ff', color: '#1677ff'}}
                                                            >
                                                                <EyeOutlined/>
                                                            </div>
                                                        </Link>
                                                    </Tooltip>
                                                    <Tooltip placement="top" title={'Edit User'}>
                                                        <Link
                                                            to={`/edit-pre-polutation/${data?._id}`}
                                                            onClick={() => breadcrumbManager(data?.name, `/edit-pre-polutation/${data?._id}`)}
                                                        >
                                                            <div
                                                                className="me-2 action-button bg-white"
                                                                style={{border: '1px solid #1677ff', color: '#1677ff'}}
                                                            >
                                                                <EditOutlined/>
                                                            </div>
                                                        </Link>

                                                    </Tooltip>

                                                    {data?.is_active ? <Popconfirm
                                                            title="Are you sure to Un-list this User?"
                                                            description="Are you sure to Un-list this User?"
                                                            onConfirm={() => handleInActiveUser(data?._id)}
                                                            onCancel={() => {
                                                            }}
                                                            okText="Yes"
                                                            cancelText="No"
                                                        >
                                                            <Tooltip placement="top" title={'Un-list User'}>
                                                                <div
                                                                    className="action-button bg-white me-2"
                                                                    style={{border: '1px solid #128314', color: '#128314'}}
                                                                >
                                                                    <CheckOutlined/>
                                                                </div>
                                                            </Tooltip>
                                                        </Popconfirm> :
                                                        <Popconfirm
                                                            title="Are you sure to List this User?"
                                                            description="Are you sure to List this User?"
                                                            onConfirm={() => handleActiveUser(data?._id)}
                                                            onCancel={() => {
                                                            }}
                                                            okText="Yes"
                                                            cancelText="No"
                                                        >
                                                            <Tooltip placement="top" title={'List User'}>
                                                                <div
                                                                    className="action-button bg-white me-2"
                                                                    style={{
                                                                        border: '1px solid #ff5200',
                                                                        color: '#ff5200'
                                                                    }}
                                                                >
                                                                    <CloseOutlined/>
                                                                </div>
                                                            </Tooltip>
                                                        </Popconfirm>
                                                    }

                                                    <Popconfirm
                                                        title="Are you sure to delete User?"
                                                        description="Are you sure to delete this User?"
                                                        onConfirm={() => deleteUser(data?._id)}
                                                        onCancel={() => {
                                                        }}
                                                        okText="Yes"
                                                        cancelText="No"
                                                    >
                                                        <Tooltip placement="top" title={'Delete User'}>
                                                            <div
                                                                className="action-button bg-white"
                                                                style={{border: '1px solid #ff4d4f', color: '#ff4d4f'}}
                                                            >
                                                                <DeleteOutlined/>
                                                            </div>
                                                        </Tooltip>
                                                    </Popconfirm>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                ) : (
                    <Empty/>
                )}
            </div>}
            {totalPage > 1 && (
                <>
                    <Pagination
                        count={totalPage}
                        defaultPage={1}
                        color="primary"
                        className="my-5 d-flex justify-content-center"
                        page={ parseInt(number)||1}
                        onChange={(event, number) => {
                            navigate(`/pre-polutation/${number}`);}}
                    />
                </>
            )}
            <ClaimSheetModel editedData={editedData} isEdit={isEdit} show={openAdd} setShow={setOpenAdd}
                             handleClose={handleClose} setpopup={setpopup}
                             setuserdata={setuserdata} userdata={userdata} setIsEdit={setIsEdit} file={file}
                             setFile={setFile} getalldata={getalldata}/>

            {popup.show && (
                <Alert
                    message={popup?.message}
                    type={popup?.success ? 'success' : 'error'}
                    showIcon
                    style={{position: 'fixed', right: '15px', bottom: '30px'}}
                />
            )}
            {isLoader && <Loader/>}
        </>
    );
};

export default ClaimUser;
