import PropTypes from 'prop-types';
import { forwardRef, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';

// project import
import { activeItem } from 'store/reducers/menu';
import { PadinationContext } from 'App';
import { BASE_URL } from 'Configration';
import { useNavigate } from '../../../../../../node_modules/react-router-dom/dist/index';
import { dispatch } from 'store/index';
import utilities from 'menu-items/utilities';

// ==============================|| NAVIGATION - LIST ITEM ||============================== //


const NavItem = ({ item, level,data }) => {
    const token = JSON.parse(localStorage.getItem('adAnimaLogin'));
     console.log(data);

     const [report, setReport] = useState(null);
    const [support, setSupports] = useState(null);
    const navigate = useNavigate();
    const userData = localStorage.getItem('adAnimaLogin');
    // useEffect(()=>{
    //   if (userData) {
    //     console.log(userData);
    //     if (JSON.parse(userData).user_type == 'sub_admin') {
    //         const userRole = JSON.parse(userData).roles;
  
    //         for (const items in utilities.children) {
    //             if (userRole [items.subname]) {
                  
                  
    //                 navigate(`${items.url}`);
    //                 break;
    //             }
    //         }
    //         // navigate('/login')
    //     }else if(JSON.parse(userData).user_type == 'admin'){

    //     }else{
    //         navigate('/login')
    //     }
    // }
    // },[])

    useEffect(() => {
        // Set report and support values when data is available
        if (data) {
            setReport(data.is_report);
            setSupports(data.is_support);
        }
    }, [data]);
    //  console.log(item.title);
     console.log(report);
     console.log(support);
        
    const theme = useTheme();
    const dispatch = useDispatch();
    const menu = useSelector((state) => state.menu);
    const { drawerOpen, openItem } = menu;
    const paginationcontext = useContext(PadinationContext);

    let itemTarget = '_self';
    if (item.target) {
        itemTarget = '_blank';
    }

    let listItemProps = { component: forwardRef((props, ref) => <Link ref={ref} {...props} to={item.url} target={itemTarget} />) };
    if (item?.external) {
        listItemProps = { component: 'a', href: item.url, target: itemTarget };
    }

    const itemHandler = (id) => {
        dispatch(activeItem({ openItem: [id] }));
        sessionStorage.setItem('activetab', JSON.stringify(id));
    };

    const localtab = JSON.parse(sessionStorage.getItem('activetab'));

    const Icon = item.icon;
    const itemIcon = item.icon ? <Icon style={{ fontSize: drawerOpen ? '1rem' : '1.25rem' }} /> : false;

    const isSelected = openItem.findIndex((id) => id === item.id) > -1;
console.log(isSelected);

    // active menu item on page load
    useEffect(() => {
        if (localtab == null) {
            dispatch(activeItem({ openItem: ['dashboard'] }));
        } else {
            dispatch(activeItem({ openItem: [localtab] }));
            
        }
    }, []);
    const [allowed, setallowed] = useState([]);

    useEffect(() => {
        // Extract allowed roles
        const newAllowed = Object.entries(token?.roles || {})
            .filter(([key, value]) => value === true) // Only keep roles with `true` value
            .map(([key]) => key); // Extract keys

     
        setallowed(newAllowed);
    }, []);
    console.log(allowed);
    
    const textColor = 'text.primary';
    const iconSelectedColor = 'primary.main';

    const isSelectedallow = 
   openItem.findIndex((id) => id === item.id) > -1;
  

    return (<>
        {token?.user_type == "sub_admin" && allowed?.includes(item.subname
        ) &&
         <ListItemButton
            {...listItemProps}
            disabled={item.disabled}
            onClick={() => {
                itemHandler(item.id);
                setReport(false);
                setSupports(false);
               
                sessionStorage.setItem('breadcrumb', JSON.stringify([{ name: item.title, url: item.url }]));
                if (item.forPagination) {
                    paginationcontext.setpagination({ ...paginationcontext.pagination, [item.forPagination]: 1 });
                }
            }}
            selected={isSelected}
            sx={{
                zIndex: 1201,
                pl: drawerOpen ? `${level * 28}px` : 1.5,
                py: !drawerOpen && level === 1 ? 1.25 : 1,
                ...(drawerOpen && {
                    '&:hover': {
                        bgcolor: 'primary.lighter'
                    },
                    '&.Mui-selected': {
                        bgcolor: 'primary.lighter',
                        borderRight: `2px solid ${theme.palette.primary.main}`,
                        color: iconSelectedColor,
                        '&:hover': {
                            color: iconSelectedColor,
                            bgcolor: 'primary.lighter'
                        }
                    }
                }),
                ...(!drawerOpen && {
                    '&:hover': {
                        bgcolor: 'transparent'
                    },
                    '&.Mui-selected': {
                        '&:hover': {
                            bgcolor: 'transparent'
                        },
                        bgcolor: 'transparent'
                    }
                })
            }}
        >
            {itemIcon && (
                <ListItemIcon
                    sx={{
                        minWidth: 28,
                        color: isSelected ? iconSelectedColor : textColor,
                        ...(!drawerOpen && {
                            borderRadius: 1.5,
                            width: 36,
                            height: 36,
                            alignItems: 'center',
                            justifyContent: 'center',
                            '&:hover': {
                                bgcolor: 'secondary.lighter'
                            }
                        }),
                        ...(!drawerOpen &&
                            isSelected && {
                                bgcolor: 'primary.lighter',
                                '&:hover': {
                                    bgcolor: 'primary.lighter'
                                }
                            })
                    }}
                >
                    {itemIcon}
                </ListItemIcon>
            )}
               
            {(drawerOpen || (!drawerOpen && level !== 1)) && (
                <ListItemText
                    primary={
                      
                        <Typography variant="h6" sx={{ color: isSelected ? iconSelectedColor : textColor }}>
                            {item.title}
                        </Typography>
                    }
                />
            )}
             {item.title === "Support" && support == true ?  <div style={{width:"10px",height:"10px",borderRadius:"50%",backgroundColor:"orange"}}></div>:""}
             {item.title === "REPORTS" && report == true ?  <div style={{width:"10px",height:"10px",borderRadius:"50%",backgroundColor:"orange"}}></div>:""}
            {(drawerOpen || (!drawerOpen && level !== 1)) && item.chip && (
                <Chip
                    color={item.chip.color}
                    variant={item.chip.variant}
                    size={item.chip.size}
                    label={item.chip.label}
                    avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
                />
            )}
        </ListItemButton>
       }
       {token?.user_type == "admin" &&<ListItemButton
            {...listItemProps}
            disabled={item.disabled}
            onClick={() => {
                itemHandler(item.id);
                setReport(false);
                setSupports(false);
               
                sessionStorage.setItem('breadcrumb', JSON.stringify([{ name: item.title, url: item.url }]));
                if (item.forPagination) {
                    paginationcontext.setpagination({ ...paginationcontext.pagination, [item.forPagination]: 1 });
                }
            }}
            selected={isSelected}
            sx={{
                zIndex: 1201,
                pl: drawerOpen ? `${level * 28}px` : 1.5,
                py: !drawerOpen && level === 1 ? 1.25 : 1,
                ...(drawerOpen && {
                    '&:hover': {
                        bgcolor: 'primary.lighter'
                    },
                    '&.Mui-selected': {
                        bgcolor: 'primary.lighter',
                        borderRight: `2px solid ${theme.palette.primary.main}`,
                        color: iconSelectedColor,
                        '&:hover': {
                            color: iconSelectedColor,
                            bgcolor: 'primary.lighter'
                        }
                    }
                }),
                ...(!drawerOpen && {
                    '&:hover': {
                        bgcolor: 'transparent'
                    },
                    '&.Mui-selected': {
                        '&:hover': {
                            bgcolor: 'transparent'
                        },
                        bgcolor: 'transparent'
                    }
                })
            }}
        >
            {itemIcon && (
                <ListItemIcon
                    sx={{
                        minWidth: 28,
                        color: isSelected ? iconSelectedColor : textColor,
                        ...(!drawerOpen && {
                            borderRadius: 1.5,
                            width: 36,
                            height: 36,
                            alignItems: 'center',
                            justifyContent: 'center',
                            '&:hover': {
                                bgcolor: 'secondary.lighter'
                            }
                        }),
                        ...(!drawerOpen &&
                            isSelected && {
                                bgcolor: 'primary.lighter',
                                '&:hover': {
                                    bgcolor: 'primary.lighter'
                                }
                            })
                    }}
                >
                    {itemIcon}
                </ListItemIcon>
            )}
               
            {(drawerOpen || (!drawerOpen && level !== 1)) && (
                <ListItemText
                    primary={
                      
                        <Typography variant="h6" sx={{ color: isSelected ? iconSelectedColor : textColor }}>
                            {item.title}
                        </Typography>
                    }
                />
            )}
             {item.title === "Support" && support == true ?  <div style={{width:"10px",height:"10px",borderRadius:"50%",backgroundColor:"orange"}}></div>:""}
             {item.title === "REPORTS" && report == true ?  <div style={{width:"10px",height:"10px",borderRadius:"50%",backgroundColor:"orange"}}></div>:""}
            {(drawerOpen || (!drawerOpen && level !== 1)) && item.chip && (
                <Chip
                    color={item.chip.color}
                    variant={item.chip.variant}
                    size={item.chip.size}
                    label={item.chip.label}
                    avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
                />
            )}
        </ListItemButton> }
        {/* <ListItemButton
            {...listItemProps}
            disabled={item.disabled}
            onClick={() => {
                itemHandler(item.id);
                setReport(false);
                setSupports(false);
               
                sessionStorage.setItem('breadcrumb', JSON.stringify([{ name: item.title, url: item.url }]));
                if (item.forPagination) {
                    paginationcontext.setpagination({ ...paginationcontext.pagination, [item.forPagination]: 1 });
                }
            }}
            selected={isSelected}
            sx={{
                zIndex: 1201,
                pl: drawerOpen ? `${level * 28}px` : 1.5,
                py: !drawerOpen && level === 1 ? 1.25 : 1,
                ...(drawerOpen && {
                    '&:hover': {
                        bgcolor: 'primary.lighter'
                    },
                    '&.Mui-selected': {
                        bgcolor: 'primary.lighter',
                        borderRight: `2px solid ${theme.palette.primary.main}`,
                        color: iconSelectedColor,
                        '&:hover': {
                            color: iconSelectedColor,
                            bgcolor: 'primary.lighter'
                        }
                    }
                }),
                ...(!drawerOpen && {
                    '&:hover': {
                        bgcolor: 'transparent'
                    },
                    '&.Mui-selected': {
                        '&:hover': {
                            bgcolor: 'transparent'
                        },
                        bgcolor: 'transparent'
                    }
                })
            }}
        >
            {itemIcon && (
                <ListItemIcon
                    sx={{
                        minWidth: 28,
                        color: isSelected ? iconSelectedColor : textColor,
                        ...(!drawerOpen && {
                            borderRadius: 1.5,
                            width: 36,
                            height: 36,
                            alignItems: 'center',
                            justifyContent: 'center',
                            '&:hover': {
                                bgcolor: 'secondary.lighter'
                            }
                        }),
                        ...(!drawerOpen &&
                            isSelected && {
                                bgcolor: 'primary.lighter',
                                '&:hover': {
                                    bgcolor: 'primary.lighter'
                                }
                            })
                    }}
                >
                    {itemIcon}
                </ListItemIcon>
            )}
               
            {(drawerOpen || (!drawerOpen && level !== 1)) && (
                <ListItemText
                    primary={
                      
                        <Typography variant="h6" sx={{ color: isSelected ? iconSelectedColor : textColor }}>
                            {item.title}
                        </Typography>
                    }
                />
            )}
             {item.title === "Support" && support == true ?  <div style={{width:"10px",height:"10px",borderRadius:"50%",backgroundColor:"orange"}}></div>:""}
             {item.title === "REPORTS" && report == true ?  <div style={{width:"10px",height:"10px",borderRadius:"50%",backgroundColor:"orange"}}></div>:""}
            {(drawerOpen || (!drawerOpen && level !== 1)) && item.chip && (
                <Chip
                    color={item.chip.color}
                    variant={item.chip.variant}
                    size={item.chip.size}
                    label={item.chip.label}
                    avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
                />
            )}
        </ListItemButton> */}
       </>

    );
};

NavItem.propTypes = {
    item: PropTypes.object,
    level: PropTypes.number
};

export default NavItem;
