import { lazy } from 'react';

import Loadable from 'components/Loadable';
import Notfound from 'pages/Notfound';
import Accesspage from 'pages/extra-pages/Accesspage';

const AuthLogin = Loadable(lazy(() => import('pages/authentication/Login')));

const LoginRoutes = {
    path: '/',

    children: [
        // { path: 'accesspath', element: <Notfound /> }
        { path: 'accesspath', element: <Accesspage /> },
        // {path:'not-found',element: <Notfound />},
    
    ]
};

const accessManagement = () => {
    const userData = localStorage.getItem('adAnimaLogin');
    if (!userData) {
        LoginRoutes.children.push({
            
                path: 'login',
                element: <AuthLogin />
            
        });
    }
};
accessManagement();
export default LoginRoutes;
