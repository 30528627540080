import React, { useEffect, useMemo, useState } from 'react';
import { Box, Grid } from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import IntroNlink from './IntroNlink';
import { activeItem } from '../../../store/reducers/menu';
import { BASE_URL } from '../../../Configration';
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import RemoveLinkPopUp from './RemoveLinkPopUp';
import service from './Service';
import Service from './Service';
import AddServicePopup from './AddServicePopup';
import toast from 'bootstrap/js/src/toast';
import plusIcon from '../../../assets/images/whiteplus.png';
import RemoveServicePopUp from './RemoveServicePopUp';
import DescSelector from './DescSelector';
import pluseIcon from '../../../assets/images/plus.png';
import { Alert } from 'antd';
import { Tooltip } from 'antd';

import { Modal, Button } from 'antd';
import { Popover, Space } from 'antd';

const EditClaimUser = () => {
    const token = JSON.parse(localStorage.getItem('adAnimaLogin'));
    const dispatch = useDispatch();
    const [userData, setUserData] = useState({});
    const [profileImage, setprofileImage] = useState('');
    // const [registration, setregistration] = useState({
    //     profile_picture: "",
    // });

    const [indexdata, setIndexdata] = useState(10);
    console.log(indexdata);

    // const [showInput, setShowInput] = useState(false);
    const [serviceImage, setserviceImage] = useState([]);
    const [selectServiceImage, setselectServiceImage] = useState([]);
    const [modelShow, setmodelShow] = useState(false);
    const [editMode, seteditMode] = useState(false);
    const [editId, setEditId] = useState('');
    const [isServices, setIsServices] = useState([]);
    const [serviceForm, setserviceForm] = useState({
        service: '',
        details: 'in_person',
        details_link: '',
        location: 'i_travel',
        locationCordinates: '',
        adress: '',
        time: '',
        session_price: '',
        member: '',
        meximum_member: '',
        addition_price: '',
        discription: '',
        session_hours: '1',
        session_minutes: '0'
    });
    const [deleteModal, setDeleteModal] = useState({
        link: false,
        service: false,
        media_link: false
    });
    const [piclength, setpiclength] = useState();
    // console.log(piclength);

    function isCheckURL(str) {
        const pattern = new RegExp(
            '^([a-zA-Z]+:\\/\\/)?' + // protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
                '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
                '(\\#[-a-z\\d_]*)?$', // fragment locator
            'i'
        );
        if (pattern.test(str)) {
            return true;
        } else {
            return false;
        }
    }
    // function checkURL(url) {
    //     return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
    // }

    const { id } = useParams();
    const [popup, setpopup] = useState({ show: false, message: '', success: true });
    const navigate = useNavigate();
    const [linkRemoveNo, setlinkRemoveNo] = useState('');
    const [userName, setUserName] = useState('');
    const [linkError, setLinkError] = useState('');
    const [loader, setLoader] = useState(false);
    const [inputData, setInputData] = useState({
        website_link: [''],
        media_link: ['']
    });
    const [err, seterr] = useState({
        provider_profile: '',
        media_link: ''
    });
    //     console.log(inputData);
    // console.log(serviceImage);

    console.log(isServices, 'isServices');
    const [modalVisible, setModalVisible] = useState(false);
    const [registration, setregistration] = useState({
        provider_services: [],
        address: '',
        latlong: {},
        profile_picture: '',
        profile_picture_image_link: '',
        provider_banner_images: [],
        delete_banner_image_ids: [],
        adress: '',
        locationCordinates: '',
        other_desc: '',
        is_other_desc: false
    });

    console.log(inputData.media_link);

    useEffect(() => {
        if (popup.show) {
            setTimeout(() => {
                setpopup(false);
            }, 3000);
        }
    }, [popup]);

    useEffect(() => {
        if (token?.is_login && token?.is_login == true) {
            dispatch(activeItem({ openItem: ['util-prePolution'] }));
            const userList = new FormData();
            userList.append('user_id', id);
            try {
                fetch(`${BASE_URL}/claim_provider/get_user_details`, {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${token.token}`
                    },
                    body: userList
                })
                    .then((response) => response.json())
                    .then((data) => {
                        console.log(data?.data?.other_desc, 'data?.data');
                        if (data.success) {
                            setUserData(data.data);
                            setUserName(data?.data?.name);
                            setprofileImage(data?.data?.profile_picture || '');
                            setregistration({
                                ...registration,
                                adress: data?.data?.address,
                                other_desc: data?.data?.other_desc
                            });
                            setselectServiceImage([]);
                            setpiclength(data?.data?.provider_banner_images?.length || 0);
                            setserviceImage(data?.data?.provider_banner_images ? data?.data?.provider_banner_images : []);
                            // setselectServiceImage(data?.data?.provider_banner_images)
                        }
                    });
            } catch (err) {
                console.log(err);
            }
        } else {
            navigate('/login');
        }
    }, [id]);
    console.log(registration.provider_banner_images);

    const getAllService = () => {
        if (token?.is_login && token?.is_login == true) {
            const userList = new FormData();
            userList.append('user_id', id);
            try {
                fetch(`${BASE_URL}/user/list_claim_services`, {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${token.token}`
                    },
                    body: userList
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.success) {
                            setIsServices(data.data);
                        }
                    });
            } catch (err) {
                console.log(err);
            }
        } else {
            navigate('/login');
        }
    };

    useEffect(() => {
        getAllService();
    }, [id]);

    const handleDeleteService = (a) => {
        const deleteuser = new FormData();
        deleteuser.append('service_id', a);
        deleteuser.append('user_id', id);
        try {
            fetch(`${BASE_URL}/claim_provider/delete_claim_service`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token.token}`
                },
                body: deleteuser
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        getAllService();
                    }
                });
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        if (userData?.website_link?.length) {
            setInputData(userData);
        } else {
            setInputData({ ...userData, website_link: [''] });
        }
    }, [userData]);
    // useEffect(() => {
    //     if (userData?.media_link?.length) {
    //         setInputData(userData);
    //     } else {
    //         setInputData({ ...userData, media_link: [''] });
    //     }
    // }, [userData]);
    console.log(registration.delete_banner_image_ids);

    const handleEditUser = () => {
        setLoader(true);
        const data = new FormData();
        data.append('user_id', id);
        if (registration.other_desc) {
            data.append('other_desc', registration.other_desc);
            data.append('is_other_desc', registration.is_other_desc);
        }

        // if (inputData?.name) {
        data.append('name', inputData?.name ? inputData?.name : '');
        // }
        // if (inputData?.email_address) {
        data.append('email_address', inputData?.email_address ? inputData?.email_address : 'null');
        // }
        // if (inputData?.country_code) {
        data.append('country_code', inputData?.country_code ? inputData?.country_code : '1');
        data.append('country_string_code', inputData?.country_string_code ? inputData?.country_string_code : 'us');
        // }
        // if (inputData?.mobile_number) {
        data.append('mobile_number', inputData?.mobile_number ? inputData?.mobile_number : '');
        // }

        data.append('provider_services', JSON.stringify(registration?.provider_services));
        // if (inputData?.provider_introduction) {
        data.append('provider_introduction', inputData?.provider_introduction ? inputData?.provider_introduction : '');
        // }
        if (inputData?.website_link?.length) {
            data.append(
                'website_links',
                JSON.stringify(
                    inputData?.website_link?.filter((E) => {
                        if (E?.trim().length !== 0) {
                            return E;
                        }
                    })
                )
            );
        }

        if (registration?.provider_banner_images && registration.provider_banner_images.length > 0) {
            registration?.provider_banner_images.forEach((item) => {
                data.append('provider_banner_images', item);
            });
        }

        data.append('delete_banner_image_ids', JSON.stringify(registration.delete_banner_image_ids));

        data.append('provider_banner_image_links', JSON.stringify(inputData?.media_link || []));

        // if (inputData?.media_link?.length) {
        //     data.append(
        //         'provider_banner_image_links',
        //         JSON.stringify(
        //             inputData?.media_link?.filter((E) => {
        //                 if (E?.trim().length !== 0) {
        //                     return E;
        //                 }
        //             })
        //         )
        //     );
        // }

        if (registration?.profile_picture != '') {
            data.append('profile_picture', registration?.profile_picture);
        } else {
            data.append('profile_picture_image_link', registration?.profile_picture_image_link);
        }

        if (registration?.locationCordinates !== '') {
            data.append('address', registration?.adress);
            data.append('location', JSON.stringify(registration?.locationCordinates));
        }

        try {
            fetch(`${BASE_URL}/claim_provider/edit_claim_provider`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token.token}`
                },
                body: data
            })
                .then((response) => response.json())
                .then((responseData) => {
                    if (responseData.success) {
                        setTimeout(() => {
                            setpopup({ show: true, message: responseData.message, success: responseData.success });
                        }, 500);
                        setTimeout(() => {
                            setLoader(false);
                            navigate(`/pre-polutation`);
                        }, 1000);
                        const newTaxData = responseData.data; // Assuming responseData.data contains the new tax data

                        // Update userdata in state
                    } else {
                        setLoader(false);
                    }
                });
        } catch (err) {
            console.log(err);
        }
    };
    const [open, setOpen] = useState(false);

    const [textBoxVisible, setTextBoxVisible] = useState(false);
    const [textValue, setTextValue] = useState('');
    const handleAddFile = () => {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.onchange = (e) => {
            const url = Array.from(e.target.files);
            const photo = url?.map((a) => {
                return window.URL.createObjectURL(a);
            });

            setprofileImage(photo);
            setregistration({
                ...registration,
                profile_picture: url[0]
            });
            e.target.value = '';
        };
        fileInput.click();
    };

    const handleShowTextBox = () => {
        setModalVisible(true);
    };

    const handleTextChange = (e) => {
        setTextValue(e.target.value);
        // Show modal when text box is interacted with
    };

    const handleModalClose = () => {
        setModalVisible(false);
        setimglink('');
    };
    const [arrow, setArrow] = useState('Show');
    console.log(profileImage);

    const [imglink, setimglink] = useState();
    console.log(setimglink);

    const content = (
        <div>
            <div onClick={handleAddFile} style={{ cursor: 'pointer', color: 'black' }}>
                Choose Media
            </div>

            <div onClick={handleShowTextBox} style={{ cursor: 'pointer', color: 'black', marginTop: '8px' }}>
            Add Link
            </div>

            <Modal title="ADD link" visible={modalVisible} onCancel={handleModalClose} footer={null}>
                <div className="group-input mt-2 p-15 clearfix ">
                    <div className="bg-white">
                        <input
                            type="url"
                            name=""
                            style={{
                                width: '88%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}
                            value={imglink}
                            onChange={(e) => {
                                setimglink(e.target.value);

                                console.log(e.target.value);
                            }}
                            placeholder="Your Link here"
                        />
                    </div>
                </div>

                {imglink == '' ? (
                    <p className="t-14-bold" style={{ color: 'red', flexWrap: 'nowrap' }}>
                        Please Enter a Link
                    </p>
                ) : (
                    ""
                )}
                {imglink && !isCheckURL(imglink) && (
                    <p className="t-14-bold" style={{ color: 'red', flexWrap: 'nowrap' }}>
                        Please Enter a Valid Link
                    </p>
                )}
              {imglink !== "" && isCheckURL(imglink) ? <div className="d-flex justify-content-center">
                    <button
                        className="btn_admina save-page-btn-position"
                        
                        onClick={() => {
                            setprofileImage(imglink);
                            setregistration({
                                ...registration,
                                profile_picture_image_link: imglink
                            });
                            setModalVisible(false);
                        }}
                    >
                        submit
                    </button></div>: <div className="d-flex justify-content-center">
                    <button
                        className="btn_admina save-page-btn-position"
                        disabled
                        style={{
                           
                            opacity: !imglink ? 0.6 : 1, // Slight transparency for disabled
                          }}
                        onClick={() => {
                            setprofileImage(imglink);
                            setregistration({
                                ...registration,
                                profile_picture_image_link: imglink
                            });
                            setModalVisible(false);
                        }}
                    >
                        submit
                    </button>
                    </div>}  
            </Modal>
        </div>
    );
    return (
        <>
            <>
                <div className="d-flex">
                    <Link to={`/pre-polutation`}>
                        <p style={{ color: '#abaaaa' }}>Pre-Polutation</p>
                    </Link>
                    <p className="mx-2">/</p>
                    <Link>
                        <p style={{ color: '#000000' }}>{userName}</p>
                    </Link>
                </div>
            </>
            <Grid container spacing={2} style={{ height: 'calc(100vh - 100px)',display:"flex" ,justifyContent:"space-around"}}>
                <Grid item xs={12} sm={2}  md={2} xl={1} lg={2} className='d-flex justify-content-center'>
                    <div className="custom-upload">
                        {profileImage != '' && <img src={profileImage} className="profile_uploaded" alt="Uploaded Profile" />}

                        <label htmlFor="file-upload" style={{ width: '100%', height: '100%' }}>
                            {/* {open &&   <input
                                id="file-upload"
                                type="file"
                                name=""
                                accept="image/png, image/jpeg"
                                onChange={(e) => {
                                    const url = Array.from(e.target.files);
                                    const photo = url?.map((a) => {
                                        return window.URL.createObjectURL(a);
                                    });

                                    setprofileImage(photo);
                                    setregistration({
                                        ...registration,
                                        profile_picture: url[0]
                                    });
                                    e.target.value = '';
                                }}
                                style={{ display: 'none' }} 
                            />} */}
                            <div
                                style={{
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <Popover placement="top" content={content} trigger="hover">
                                    <img src={pluseIcon} className="profile_uploaded-after" alt="Add Profile" />
                                </Popover>
                            </div>
                        </label>
                    </div>
                </Grid>
                <Grid item xs={12} sm={10} lg={10} md={8} xl={10} xxl={11} >
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4} >
                            <div className="group-input mt-3">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M15.25 6C15.25 7.79493 13.7949 9.25 12 9.25V10.75C14.6234 10.75 16.75 8.62335 16.75 6H15.25ZM12 9.25C10.2051 9.25 8.75 7.79493 8.75 6H7.25C7.25 8.62335 9.37665 10.75 12 10.75V9.25ZM8.75 6C8.75 4.20507 10.2051 2.75 12 2.75V1.25C9.37665 1.25 7.25 3.37665 7.25 6H8.75ZM12 2.75C13.7949 2.75 15.25 4.20507 15.25 6H16.75C16.75 3.37665 14.6234 1.25 12 1.25V2.75ZM9 13.75H15V12.25H9V13.75ZM15 20.25H9V21.75H15V20.25ZM9 20.25C7.20507 20.25 5.75 18.7949 5.75 17H4.25C4.25 19.6234 6.37665 21.75 9 21.75V20.25ZM18.25 17C18.25 18.7949 16.7949 20.25 15 20.25V21.75C17.6234 21.75 19.75 19.6234 19.75 17H18.25ZM15 13.75C16.7949 13.75 18.25 15.2051 18.25 17H19.75C19.75 14.3766 17.6234 12.25 15 12.25V13.75ZM9 12.25C6.37665 12.25 4.25 14.3766 4.25 17H5.75C5.75 15.2051 7.20507 13.75 9 13.75V12.25Z"
                                        fill="#363853"
                                    />
                                </svg>
                                <input
                                    type="text"
                                    required
                                    placeholder="Full Name"
                                    value={inputData?.name?.replace(/\d/g, '')}
                                    onChange={(e) => {
                                        if (e.target.value.length <= 25) {
                                            setInputData((pre) => ({ ...pre, name: e.target.value }));
                                        }
                                    }}
                                />
                            </div>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <div className="group-input mt-3">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g id="Icons">
                                        <path
                                            id="Vector"
                                            d="M2.88539 8.84875C3.55805 6.13983 5.70602 4.04534 8.43056 3.44162L8.88443 3.34105C10.9366 2.88632 13.0634 2.88632 15.1156 3.34105L15.5694 3.44162C18.294 4.04534 20.442 6.13984 21.1146 8.84875C21.6285 10.9182 21.6285 13.0819 21.1146 15.1512C20.442 17.8602 18.294 19.9547 15.5694 20.5584L15.1156 20.659C13.0634 21.1137 10.9366 21.1137 8.88443 20.659L8.43056 20.5584C5.70601 19.9547 3.55805 17.8602 2.88539 15.1513C2.37154 13.0819 2.37154 10.9181 2.88539 8.84875Z"
                                            stroke="#363853"
                                            strokeWidth="1.5"
                                            stroke-linecap="round"
                                        />
                                        <path
                                            id="Vector_2"
                                            d="M21.1146 15.1512C21.6285 13.0819 21.6285 10.9182 21.1146 8.84875C20.442 6.13984 18.294 4.04534 15.5694 3.44162L15.1156 3.34105C13.0634 2.88632 10.9366 2.88632 8.88443 3.34105L8.43056 3.44162C5.70602 4.04534 3.55805 6.13983 2.88539 8.84875C2.37154 10.9181 2.37154 13.0819 2.88539 15.1513C3.55805 17.8602 5.70601 19.9547 8.43056 20.5584L8.88443 20.659"
                                            stroke="#363853"
                                            strokeWidth="1.5"
                                            stroke-linecap="round"
                                        />
                                        <path
                                            id="Vector_3"
                                            d="M8.15112 10.3501L10.7216 12.1866C11.4864 12.7329 12.5136 12.7329 13.2783 12.1866L15.8489 10.3501"
                                            stroke="#363853"
                                            strokeWidth="1.5"
                                            stroke-linecap="round"
                                        />
                                    </g>
                                </svg>
                                <input
                                    type="email"
                                    required
                                    placeholder="Email Address"
                                    value={inputData?.email_address == 'null' ? '' : inputData?.email_address}
                                    onChange={(e) => {
                                        setInputData((pre) => ({
                                            ...pre,
                                            email_address: e.target.value
                                        }));
                                    }}
                                />
                            </div>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <div className="group-input mt-3">
                                <PhoneInput
                                    //  country={"us"}
                                    placeholder="Phone Number"
                                    enableSearch={true}
                                    value={`${inputData?.country_code}${inputData?.mobile_number}`}
                                    onChange={(phone, data) => {
                                        console.log(data);

                                        setInputData((pre) => ({
                                            ...pre,
                                            mobile_number: phone.slice(data?.dialCode?.length),
                                            country_code: data.dialCode,
                                            country_string_code: data.countryCode
                                        }));
                                    }}
                                />
                            </div>
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <IntroNlink
                                inputData={inputData}
                                deleteModal={deleteModal}
                                setDeleteModal={setDeleteModal}
                                setInputData={setInputData}
                                setlinkRemoveNo={setlinkRemoveNo}
                                linkRemoveNo={linkRemoveNo}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <DescSelector inputData={inputData} registration={registration} setregistration={setregistration} />
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <Service
                                isServices={isServices}
                                setmodelShow={setmodelShow}
                                inputData={inputData}
                                seteditMode={seteditMode}
                                setserviceForm={setserviceForm}
                                setEditId={setEditId}
                                setDeleteModal={setDeleteModal}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Box className="inner-gred page-Container-height">
                                <div>
                                    <div className="d-flex justify-content-between ">
                                        {/* <h4 className="input-label " style={{marginTop:"10px"}}>Add Media</h4> */}
                                        <p>Upload Services Pictures </p>
                                        {piclength < 7 && (
                                            <div className="d-felx ">
                                                <button
                                                    className="btn btn-light"
                                                    onClick={() => {
                                                        if (inputData?.media_link) {
                                                            if (isCheckURL(inputData?.media_link[inputData?.media_link?.length - 1])) {
                                                                setInputData({
                                                                    ...inputData,
                                                                    media_link: [...inputData?.media_link, '']
                                                                });
                                                                console.log(inputData?.media_link?.length);

                                                                setIndexdata(inputData?.media_link?.length);
                                                                setpiclength(piclength + 1);
                                                            } else {
                                                                setIndexdata(10);
                                                            }
                                                        } else {
                                                            setInputData({
                                                                ...inputData,
                                                                media_link: [...(inputData?.media_link || ''), '']
                                                            });
                                                            setpiclength(piclength + 1);
                                                            setLinkError('');
                                                            setIndexdata(0);
                                                        }
                                                    }}
                                                >
                                                    + Add Link
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                    <div className="multi_imgs mt-3">
                                        {serviceImage?.map((value, i) => {
                                            return (
                                                <>
                                                    <div className="hexagone">
                                                        <span
                                                            className="remove"
                                                            onClick={() => {
                                                                console.log(value._id);

                                                                const newImage = serviceImage?.filter((data, j) => {
                                                                    return i !== j;
                                                                });
                                                                const newFormData = serviceImage?.filter((data, j) => {
                                                                    return i == j;
                                                                });
                                                                setserviceImage(newImage);

                                                                setregistration({
                                                                    ...registration,
                                                                    delete_banner_image_ids: [
                                                                        ...registration.delete_banner_image_ids,
                                                                        value._id
                                                                    ]
                                                                });
                                                                setpiclength(piclength - newFormData.length);
                                                            }}
                                                        >
                                                            <svg
                                                                width="20"
                                                                height="20"
                                                                viewBox="0 0 20 20"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <g id="Icons">
                                                                    <path
                                                                        id="Vector"
                                                                        d="M12.3865 12.8635L7.61351 8.09054M7.61351 12.8635L12.3865 8.09054"
                                                                        stroke="#FF0000"
                                                                        strokeWidth="1.39212"
                                                                        stroke-linecap="round"
                                                                    />
                                                                </g>
                                                            </svg>
                                                        </span>
                                                        <img src={value?.file_name} />
                                                    </div>
                                                </>
                                            );
                                        })}
                                        {selectServiceImage?.map((value, i) => {
                                            return (
                                                <>
                                                    <div className="hexagone">
                                                        <span
                                                            className="remove"
                                                            onClick={() => {
                                                                const newImage = selectServiceImage?.filter((data, j) => {
                                                                    return i !== j;
                                                                });
                                                                const newData = selectServiceImage?.filter((data, j) => {
                                                                    return i == j;
                                                                });
                                                                const newFormData = registration.provider_banner_images?.filter(
                                                                    (data, j) => {
                                                                        return i !== j;
                                                                    }
                                                                );
                                                                console.log(newData.length);

                                                                setselectServiceImage(newImage);
                                                                setregistration({
                                                                    ...registration,
                                                                    provider_banner_images: newFormData
                                                                });
                                                                setpiclength(piclength - newData.length);
                                                            }}
                                                        >
                                                            <svg
                                                                width="20"
                                                                height="20"
                                                                viewBox="0 0 20 20"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <g id="Icons">
                                                                    <path
                                                                        id="Vector"
                                                                        d="M12.3865 12.8635L7.61351 8.09054M7.61351 12.8635L12.3865 8.09054"
                                                                        stroke="#FF0000"
                                                                        strokeWidth="1.39212"
                                                                        stroke-linecap="round"
                                                                    />
                                                                </g>
                                                            </svg>
                                                        </span>
                                                        <img src={value.file_name} />
                                                    </div>
                                                </>
                                            );
                                        })}
                                        {piclength < 7 && (
                                            <>
                                                <div className="d-inline-block">
                                                    <div className="custom-upload-2">
                                                        <input
                                                            style={{ display: 'inline-block' }}
                                                            type="file"
                                                            multiple={true}
                                                            name=""
                                                            accept="image/png, image/jpeg"
                                                            onChange={(e) => {
                                                                const files = e.target.files; // Get selected files
                                                                const ImageArray = Array.from(e.target.files);
                                                                console.log(ImageArray, 'ImageArray');
                                                                let takeimg = 7 - piclength;
                                                                const dataimg = ImageArray.slice(0, takeimg);
                                                                console.log(dataimg.length);

                                                                setpiclength(piclength + dataimg.length);
                                                                console.log(piclength);

                                                                setregistration((pre) => ({
                                                                    ...pre,
                                                                    provider_banner_images: [...pre.provider_banner_images, ...dataimg]
                                                                }));

                                                                if (files) {
                                                                    const newImageObjects = Array.from(files).map((file) => ({
                                                                        file_name: window.URL.createObjectURL(file)
                                                                    }));

                                                                    const data = newImageObjects.slice(0, takeimg);

                                                                    setselectServiceImage((prevImages) => [...prevImages, ...data]);

                                                                    // setregistration((prevRegistration) => ({
                                                                    //     ...prevRegistration,
                                                                    //     provider_banner_images: [
                                                                    //         ...prevRegistration.provider_banner_images,
                                                                    //         ...Array.from(files),
                                                                    //     ],
                                                                    // }));
                                                                }

                                                                // Clear the file input value to allow re-selection of the same file
                                                                e.target.value = '';
                                                            }}
                                                        />
                                                        <img src={pluseIcon} />
                                                    </div>

                                                    <Box></Box>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className="">
                                    <Grid container spacing={2} style={{ marginTop: '3px' }}>
                                        {inputData?.media_link?.map((data, i) => {
                                            return (
                                                <>
                                                    <Grid item xs={inputData?.media_link?.length > 1 ? 6 : 12}>
                                                        <div className="group-input mt-2 p-15 clearfix ">
                                                            <div className="bg-white">
                                                                <input
                                                                    type="url"
                                                                    name=""
                                                                    style={{
                                                                        width: '88%',
                                                                        whiteSpace: 'nowrap',
                                                                        overflow: 'hidden',
                                                                        textOverflow: 'ellipsis'
                                                                    }}
                                                                    value={inputData?.media_link[i]}
                                                                    onChange={(e) => {
                                                                        const newValue = inputData?.media_link?.map((value, j) => {
                                                                            if (i == j) {
                                                                                return e.target.value;
                                                                            } else {
                                                                                return value;
                                                                            }
                                                                        });
                                                                        setInputData({
                                                                            ...inputData,
                                                                            media_link: newValue
                                                                        });
                                                                        setIndexdata(10);
                                                                    }}
                                                                    placeholder="Your Link here"
                                                                />
                                                            </div>

                                                            {inputData?.media_link?.length > 0 && (
                                                                <>
                                                                    <Tooltip placement="top" title={'Delete Link'}>
                                                                        <span
                                                                            className="remove new-remove"
                                                                            style={{ top: '16px', right: '16px' }}
                                                                            onClick={() => {
                                                                                const newValue = inputData?.media_link?.filter(
                                                                                    (value, j) => {
                                                                                        return j !== i;
                                                                                    }
                                                                                );
                                                                                const newdataValue = inputData?.media_link?.filter(
                                                                                    (value, j) => {
                                                                                        return j == i;
                                                                                    }
                                                                                );
                                                                                console.log(newdataValue.length);

                                                                                setInputData({
                                                                                    ...inputData,
                                                                                    media_link: newValue
                                                                                });
                                                                                setIndexdata(10);
                                                                                setpiclength(piclength - newdataValue.length);
                                                                                // setDeleteModal((pre) => ({
                                                                                //     ...pre,
                                                                                //     media_link: true
                                                                                // }));
                                                                                // setlinkRemoveNo(i);
                                                                                // setLinkError('');

                                                                                // setWebLinks({
                                                                                //     ...webLinks,
                                                                                //     webLinks: webLinks?.filter((datanew, j) => {
                                                                                //         return i !== j;
                                                                                //     }),
                                                                                // });
                                                                            }}
                                                                        >
                                                                            <svg
                                                                                style={{ position: 'initial' }}
                                                                                width="20"
                                                                                height="20"
                                                                                viewBox="0 0 20 20"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <g id="Icons">
                                                                                    <path
                                                                                        id="Vector"
                                                                                        d="M12.3865 12.8635L7.61351 8.09054M7.61351 12.8635L12.3865 8.09054"
                                                                                        stroke="#FF0000"
                                                                                        strokeWidth="1.39212"
                                                                                        stroke-linecap="round"
                                                                                    ></path>
                                                                                </g>
                                                                            </svg>
                                                                        </span>
                                                                    </Tooltip>
                                                                </>
                                                            )}
                                                        </div>

                                                        {data == '' && indexdata !== i ? (
                                                            <p className="t-14-bold" style={{ color: 'red', flexWrap: 'nowrap' }}>
                                                                Please Enter a Link
                                                            </p>
                                                        ) : (
                                                            ''
                                                        )}
                                                        {data && !isCheckURL(data) && (
                                                            <p className="t-14-bold" style={{ color: 'red', flexWrap: 'nowrap' }}>
                                                                Please Enter a Valid Link
                                                            </p>
                                                        )}
                                                    </Grid>
                                                </>
                                            );
                                        })}
                                    </Grid>
                                </div>
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={12} style={{ marginTop: isServices.length ? '0' : '12%' }}>
                            <Box className="d-flex flex-wrap justify-content-center justify-content-xl-start align-items-center mt-3 mb-3">
                                <button className="btn_admina" style={{ maxWidth: '400px' }} onClick={() => setmodelShow(true)}>
                                    <img src={plusIcon} alt="" style={{ marginRight: '10px' }} />
                                    Add Service
                                </button>
                                <button
                                    className="btn_White ms-lg-auto"
                                    onClick={() => {
                                        setInputData(userData);
                                        navigate('/pre-polutation');
                                    }}
                                >
                                    Cancel
                                </button>
                                {/*<div className="btn_gred mt-4">*/}
                                {/*    <button*/}
                                {/*        type="submit"*/}
                                {/*        className="btn_admina"*/}
                                {/*    >*/}
                                {/*        Save*/}
                                {/*    </button>*/}
                                {loader ? (
                                    <a href="#" onClick={(e) => e.preventDefault()} className="btn_admina ">
                                        {/* save */}
                                        <div className="loader"></div>
                                    </a>
                                ) : (
                                    <button
                                        className="btn_admina save-page-btn-position"
                                        onClick={() => {
                                            // if (inputData?.provider_introduction.trim()) {
                                            handleEditUser();
                                            // dispatch(addMyPageDetails({...inputData, deleteId: deleteId},));
                                            setLinkError('');
                                            // } else {
                                            //     toast.error("Please Add Provider Introductions.")
                                            // }
                                        }}
                                    >
                                        Save
                                    </button>
                                )}
                                {/*<button*/}
                                {/*    className="btn_admina save-page-btn-position"*/}
                                {/*    onClick={() => {*/}
                                {/*        // if (inputData?.provider_introduction.trim()) {*/}
                                {/*        handleEditUser();*/}
                                {/*        // dispatch(addMyPageDetails({...inputData, deleteId: deleteId},));*/}
                                {/*        setLinkError('');*/}
                                {/*        // } else {*/}
                                {/*        //     toast.error("Please Add Provider Introductions.")*/}
                                {/*        // }*/}
                                {/*    }}*/}
                                {/*>*/}
                                {/*    Save*/}
                                {/*</button>*/}
                                {/*</div>*/}
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {/*.............pop-up's............*/}

            <RemoveLinkPopUp
                open={deleteModal}
                setOpen={setDeleteModal}
                onConfirm={() => {
                    if (deleteModal.link == true) {
                        const newValue = inputData?.website_link?.filter((value, j) => {
                            return j !== linkRemoveNo;
                        });
                        setInputData({
                            ...inputData,
                            website_link: newValue
                        });
                    }
                    // if (deleteModal.media_link == true) {
                    //     const newValue = inputData?.media_link?.filter((value, j) => {
                    //         return j !== linkRemoveNo;
                    //     });
                    //     const newdataValue = inputData?.media_link?.filter((value, j) => {
                    //         return j == linkRemoveNo;
                    //     });
                    //     console.log(newdataValue.length);

                    //     setInputData({
                    //         ...inputData,
                    //         media_link: newValue
                    //     });

                    //     setpiclength(piclength - newdataValue.length );
                    //     console.log(piclength- newdataValue.length);
                    // }
                    setDeleteModal((pre) => ({ ...pre, link: false }));
                }}
            />
            {/* <RemoveLinkPopUp
                open={deleteModal}
                setOpen={setDeleteModal}
                onConfirm={() => {
                    const newValue = inputData?.media_link?.filter((value, j) => {
                        return j !== linkRemoveNo;
                    });
                    setInputData({
                        ...inputData,
                        media_link: newValue
                    });
                }}
            /> */}

            <RemoveServicePopUp open={deleteModal} setOpen={setDeleteModal} onConfirm={() => handleDeleteService(editId)} />

            <AddServicePopup
                getAllService={getAllService}
                modelShow={modelShow}
                setmodelShow={setmodelShow}
                setserviceForm={setserviceForm}
                seteditMode={seteditMode}
                setInputData={setInputData}
                editMode={editMode}
                inputData={inputData}
                serviceForm={serviceForm}
                editId={editId}
            />

            {popup.show && (
                <Alert
                    message={popup?.message}
                    type={popup?.success ? 'success' : 'error'}
                    showIcon
                    style={{ position: 'fixed', right: '15px', bottom: '30px', zIndex: '1000' }}
                />
            )}
        </>
    );
};

export default EditClaimUser;
