import React, {useState} from 'react';
import {Modal} from "react-bootstrap-v5";
import PlacesAutocomplete from "react-places-autocomplete";
import {
    geocodeByAddress, geocodeByPlaceId, getLatLng,
} from "react-places-autocomplete";
import locationIcon from "../../../assets/images/Discovery.svg"
import {BASE_URL} from "../../../Configration";
import {useParams} from "react-router-dom";


const minit = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", '19', "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', "49", '50', '51', '52', '53', "54", '55', "56", "57", "58", "59"]

const AddServicePopup = ({
                             getAllService,
                             modelShow,
                             setmodelShow,
                             setserviceForm,
                             seteditMode,
                             editMode,
                             inputData,
                             setInputData,
                             serviceForm,
                             editId
                         }) => {
    const minitObjects = minit.map(item => ({
        label: item.padStart(2, "0"), value: item
    }));

    const token = JSON.parse(localStorage.getItem('adAnimaLogin'));
    const {id} = useParams();
    const [loader, setLoader] = useState(false);
    const [isError, setIsError] = useState("");

    const handleAddService = () => {
        setLoader(true);
        const data = new FormData();
        data.append('user_id', id);
        data.append('service_name', serviceForm?.service);
        data.append('service_type', serviceForm?.details);
        data.append('location_type', serviceForm?.location);
        if (serviceForm?.locationCordinates) {
            data.append('location', JSON.stringify(serviceForm?.locationCordinates));
        }
        if (serviceForm?.adress) {
            data.append('address', serviceForm?.adress);
        }
        data.append('session_link', serviceForm?.details_link);
        data.append('session_minute', serviceForm?.session_minutes || 0);
        data.append('session_price', serviceForm?.session_price);
        data.append('participants', serviceForm?.member);
        data.append('additional_price', serviceForm?.addition_price);
        data.append('maximum_participants', serviceForm?.meximum_member);
        data.append('description', serviceForm?.discription);
        data.append('session_hour', serviceForm?.session_hours || 1);


        try {
            fetch(`${BASE_URL}/claim_provider/add_claim_service`, {
                method: 'POST', headers: {
                    Authorization: `Bearer ${token.token}`
                }, body: data
            })
                .then((response) => response.json())
                .then((responseData) => {
                    // setpopup({ show: true, message: responseData.message, success: responseData.success });

                    if (responseData.success) {
                        setLoader(false)
                        getAllService()
                        setmodelShow(false)
                        // const newTaxData = responseData.data; // Assuming responseData.data contains the new tax data
                        //
                        // // Update userdata in state
                        // setuserdata(prevData => {
                        //     const newData = [...prevData, newTaxData];
                        //     localStorage.setItem('add_sales_tax', JSON.stringify(newData));
                        //     return newData;
                        // });
                        //
                        // handleClose();
                    }else {
                        setLoader(false)
                    }
                });
        } catch (err) {
            setLoader(false)
            console.log(err);
        }
    };

    const handleEditService = () => {
        const data = new FormData();
        data.append('user_id', id);
        data.append('service_name', serviceForm?.service);
        data.append('service_type', serviceForm?.details);
        data.append('location_type', serviceForm?.location);
        if (serviceForm?.locationCordinates) {
            data.append('location', JSON.stringify(serviceForm?.locationCordinates));
        }
        if (serviceForm?.adress) {
            data.append('address', serviceForm?.adress);
        }
        data.append('session_link', serviceForm?.details_link);
        data.append('session_minute', serviceForm?.session_minutes || 0);
        data.append('session_price', serviceForm?.session_price ? serviceForm?.session_price : "");
        data.append('participants', serviceForm?.member);
        data.append('additional_price', serviceForm?.addition_price);
        data.append('maximum_participants', serviceForm?.meximum_member);
        data.append('description', serviceForm?.discription);
        data.append('session_hour', serviceForm?.session_hours || 1);
        data.append('service_id', editId);


        try {
            fetch(`${BASE_URL}/user/edit_claim_service`, {
                method: 'POST', headers: {
                    Authorization: `Bearer ${token.token}`
                }, body: data
            })
                .then((response) => response.json())
                .then((responseData) => {
                    // setpopup({ show: true, message: responseData.message, success: responseData.success });

                    if (responseData.success) {
                        getAllService()
                        setLoader(false)
                        // const newTaxData = responseData.data; // Assuming responseData.data contains the new tax data
                        //
                        // // Update userdata in state
                        // setuserdata(prevData => {
                        //     const newData = [...prevData, newTaxData];
                        //     localStorage.setItem('add_sales_tax', JSON.stringify(newData));
                        //     return newData;
                        // });
                        //
                        // handleClose();
                    }else {
                        setLoader(false)
                    }
                });
        } catch (err) {
            console.log(err);
        }
    };

    console.log(serviceForm.session_hours == 0 && serviceForm.session_minutes == 0, 'sessio-hours')

    return (<>
        <Modal
            centered
            className="largemodal add_service_modal"
            show={modelShow}
            onHide={() => {
                setmodelShow(false);
                setIsError("")
                setserviceForm({
                    service: "",
                    details: "in_person",
                    details_link: "",
                    location: "i_travel",
                    adress: "",
                    time: "",
                    session_price: "",
                    member: "",
                    meximum_member: "",
                    addition_price: "",
                    discription: "",
                    locationCordinates: "",
                    session_hours: "",
                    session_minutes: "",
                });
                seteditMode(false);
            }}
        >
            <div>
                <button
                    type="button"
                    className="close-modal"
                    onClick={() => {
                        setmodelShow(false);
                        setIsError("")
                        setserviceForm({
                            service: "",
                            details: "in_person",
                            details_link: "",
                            location: "i_travel",
                            adress: "",
                            time: "",
                            session_price: "",
                            member: "",
                            meximum_member: "",
                            addition_price: "",
                            discription: "",
                            locationCordinates: "",
                            session_hours: "",
                            session_minutes: "",
                        });
                        seteditMode(false);
                    }}
                    style={{position: "absolute", right: "20px", top: "20px"}}
                >
                    <svg
                        width="50"
                        height="50"
                        viewBox="0 0 50 50"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle cx="25" cy="25" r="25" fill="#EEEEEE"/>
                        <path
                            d="M25 38.4375C17.5875 38.4375 11.5625 32.4125 11.5625 25C11.5625 17.5875 17.5875 11.5625 25 11.5625C32.4125 11.5625 38.4375 17.5875 38.4375 25C38.4375 32.4125 32.4125 38.4375 25 38.4375ZM25 13.4375C18.625 13.4375 13.4375 18.625 13.4375 25C13.4375 31.375 18.625 36.5625 25 36.5625C31.375 36.5625 36.5625 31.375 36.5625 25C36.5625 18.625 31.375 13.4375 25 13.4375Z"
                            fill="#363853"
                        />
                        <path
                            d="M21.4627 29.4752C21.2252 29.4752 20.9877 29.3877 20.8002 29.2002C20.4377 28.8377 20.4377 28.2377 20.8002 27.8752L27.8752 20.8002C28.2377 20.4377 28.8377 20.4377 29.2002 20.8002C29.5627 21.1627 29.5627 21.7627 29.2002 22.1252L22.1252 29.2002C21.9502 29.3877 21.7002 29.4752 21.4627 29.4752Z"
                            fill="#363853"
                        />
                        <path
                            d="M28.5377 29.4752C28.3002 29.4752 28.0627 29.3877 27.8752 29.2002L20.8002 22.1252C20.4377 21.7627 20.4377 21.1627 20.8002 20.8002C21.1627 20.4377 21.7627 20.4377 22.1252 20.8002L29.2002 27.8752C29.5627 28.2377 29.5627 28.8377 29.2002 29.2002C29.0127 29.3877 28.7752 29.4752 28.5377 29.4752Z"
                            fill="#363853"
                        />
                    </svg>
                </button>
                <form
                    className="login_margin mt-2"
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (serviceForm.session_hours == 0 && serviceForm.session_minutes == 0) {
                            setIsError("Please select Session Time")
                        } else {
                            setIsError("")
                            if (editMode) {
                                handleEditService()
                                setInputData({
                                    ...inputData,
                                    my_page_service_detail: inputData?.my_page_service_detail?.map((value, i) => {
                                        if (i == editId) {
                                            return {
                                                ...value, // service_id : value?._id,
                                                service_name: serviceForm?.service,
                                                service_type: serviceForm?.details,
                                                location_type: serviceForm?.location,
                                                link: serviceForm?.details_link,
                                                location: serviceForm?.locationCordinates,
                                                address: serviceForm?.adress,
                                                session_hour: serviceForm?.session_hours || 1,
                                                session_minute: serviceForm?.session_minutes || 0,
                                                session_price: serviceForm?.session_price,
                                                participants: serviceForm?.member,
                                                additional_price: serviceForm?.addition_price,
                                                maximum_participants: serviceForm?.meximum_member,
                                                description: serviceForm?.discription,
                                            };
                                        } else {
                                            return value;
                                        }
                                    }),
                                });
                            } else {
                                handleAddService()
                                setInputData({
                                    ...inputData, my_page_service_detail: [...inputData?.my_page_service_detail, {
                                        service_name: serviceForm?.service,
                                        service_type: serviceForm?.details,
                                        location_type: serviceForm?.location,
                                        link: serviceForm?.details_link,
                                        location: serviceForm?.locationCordinates,
                                        address: serviceForm?.adress,
                                        session_hour: serviceForm?.session_hours || 1,
                                        session_minute: serviceForm?.session_minutes || 0,
                                        session_price: serviceForm?.session_price,
                                        participants: serviceForm?.member,
                                        additional_price: serviceForm?.addition_price,
                                        maximum_participants: serviceForm?.meximum_member,
                                        description: serviceForm?.discription,
                                    },],
                                });
                            }
                            setserviceForm({
                                service: "",
                                details: "in_person",
                                details_link: "",
                                location: "i_travel",
                                adress: "",
                                time: "",
                                session_price: "",
                                member: "",
                                meximum_member: "",
                                addition_price: "",
                                discription: "",
                                locationCordinates: "",
                                session_hours: "1",
                                session_minutes: "0",
                            });

                            seteditMode(false);
                            setmodelShow(false);
                        }

                    }}
                >
                    <h3 className="mb-3 mb-md-3 page-title">Add Service</h3>
                    <div className="row mt-5">
                        <div className="col-12 col-md-6">
                            <div className="group-input mt-2 p-15 clearfix">
                                <input
                                    type="text"
                                    name=""
                                    required
                                    value={serviceForm.service}
                                    onChange={(e) => {
                                        if (e.target.value.length <= 50) {
                                            setserviceForm({...serviceForm, service: e.target.value});
                                        }
                                    }}
                                    placeholder="Enter service"
                                />
                            </div>
                            <div className="detail_location">
                                <h4 className="text-center">Details</h4>
                                <div className="tablist">
                                <span
                                    className={serviceForm.details == "in_person" ? "t_active" : ""}
                                    onClick={() => {
                                        setserviceForm({...serviceForm, details: "in_person"});
                                    }}
                                >
                                    In Person
                                </span>
                                    <span
                                        className={serviceForm.details == "virtual" ? "t_active" : ""}
                                        onClick={() => {
                                            setserviceForm({...serviceForm, details: "virtual"});
                                        }}
                                    >
                                           Virtual
                                         </span>
                                    <span
                                        className={serviceForm.details == "both" ? "t_active" : ""}
                                        onClick={() => {
                                            setserviceForm({...serviceForm, details: "both"});
                                        }}
                                    >
                                    Both
                                </span>
                                </div>
                                {(serviceForm.details !== "in_person" && serviceForm.details !== "Details") && (<>
                                    <div className="group-input mt-3 p-15 clearfix">
                                        <input
                                            type="url"
                                            name=""
                                            value={serviceForm.details_link}
                                            onChange={(e) => {
                                                setserviceForm({
                                                    ...serviceForm, details_link: e.target.value,
                                                });
                                            }}
                                            placeholder="Enter link"
                                        />
                                    </div>
                                </>)}

                                {serviceForm.details !== "virtual" && <>
                                    <div className="text-center location_title">
                                        <h4 className="text-center d-inline-block">
                                            <img
                                                src={locationIcon}
                                                className="d-inline-block me-1"
                                                style={{verticalAlign: "top"}}
                                            />
                                            Location
                                        </h4>
                                    </div>
                                    <div className="tablist">
                                <span
                                    className={serviceForm.location == "i_travel" ? "t_active" : ""}
                                    onClick={() => {
                                        setserviceForm({...serviceForm, location: "i_travel"});
                                    }}
                                >
                                    I Travel
                                </span>
                                        <span
                                            className={serviceForm.location == "i_host" ? "t_active" : ""}
                                            onClick={() => {
                                                setserviceForm({...serviceForm, location: "i_host"});
                                            }}
                                        >
                                    I Host
                                </span>
                                        <span
                                            className={serviceForm.location == "both" ? "t_active" : ""}
                                            onClick={() => {
                                                setserviceForm({...serviceForm, location: "both"});
                                            }}
                                        >
                                    Both
                                </span>
                                    </div>
                                    {serviceForm.location !== "i_travel" && (<>
                                        <div className="group-input mt-3 p-15 clearfix">
                                            <PlacesAutocomplete
                                                value={serviceForm.adress}
                                                onChange={(e) => {
                                                    setserviceForm({
                                                        ...serviceForm, adress: e,
                                                    });
                                                }}
                                                onSelect={(address) => {
                                                    geocodeByAddress(address)
                                                        .then((results) => getLatLng(results[0]))
                                                        .then((latLng) => {
                                                            setserviceForm({
                                                                ...serviceForm, adress: address, locationCordinates: {
                                                                    type: "Point",
                                                                    coordinates: [latLng.lng, latLng.lat],
                                                                },
                                                            });
                                                        })
                                                        .catch((error) => console.error("Error", error));
                                                }}
                                            >
                                                {({
                                                      getInputProps, suggestions, getSuggestionItemProps, loading,
                                                  }) => (<div>
                                                    <input
                                                        required
                                                        {...getInputProps({
                                                            placeholder: "Search Places ...",
                                                            className: "location-search-input",
                                                        })}
                                                    />
                                                    <div className="autocomplete-dropdown-container">
                                                        {loading && <div>Loading...</div>}
                                                        {suggestions?.map((suggestion) => {
                                                            const className = suggestion.active ? "suggestion-item--active" : "suggestion-item";
                                                            // inline style for demonstration purpose
                                                            const style = suggestion.active ? {
                                                                backgroundColor: "#fafafa", cursor: "pointer",
                                                            } : {
                                                                backgroundColor: "#ffffff", cursor: "pointer",
                                                            };
                                                            return (<div
                                                                {...getSuggestionItemProps(suggestion, {
                                                                    className, style,
                                                                })}
                                                            >
                                                                <span>{suggestion.description}</span>
                                                            </div>);
                                                        })}
                                                    </div>
                                                </div>)}
                                            </PlacesAutocomplete>
                                        </div>
                                    </>)}
                                </>}
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="detail_location mt-0">
                                <h4 className="text-center">Time Frame</h4>

                                <div className="row">
                                    <div className="col-12 col-sm-6 mt-3">
                                        <label htmlFor="">Session Hours</label>
                                        {/*<div className="time-picker  mt-2">*/}
                                        {/*    <LocalizationProvider dateAdapter={AdapterDayjs} sx={{*/}
                                        {/*        backgroundColor: "white",*/}
                                        {/*        paddingTop: "0 !important"*/}
                                        {/*    }}>*/}
                                        {/*        <DemoContainer*/}
                                        {/*            components={['MobileTimePicker', 'MobileTimePicker', 'MobileTimePicker']}*/}
                                        {/*        >*/}
                                        {/*            <DemoItem sx={{backgroundColor: "white !important"}}>*/}
                                        {/*                <TimePicker ampm={false} views={['hours']}*/}
                                        {/*                            required*/}
                                        {/*                            value={dayjs(`2022-04-17T${serviceForm.session_hours}:30`)}*/}
                                        {/*                            sx={{*/}
                                        {/*                                backgroundColor: "white",*/}
                                        {/*                                borderRadius: "5px",*/}

                                        {/*                                outline: "none"*/}
                                        {/*                            }} format="h" onChange={(newValue) => {*/}
                                        {/*                    setserviceForm({*/}
                                        {/*                        ...serviceForm, session_hours: newValue?.$H,*/}
                                        {/*                    });*/}
                                        {/*                }}/>*/}
                                        {/*            </DemoItem>*/}
                                        {/*        </DemoContainer>*/}
                                        {/*    </LocalizationProvider>*/}
                                        <div className="group-input mt-2 p-15 clearfix">
                                            <select name="cars" style={{fontSize: "15px"}} id="cars" required
                                                    value={serviceForm.session_hours} onChange={(e) => {
                                                setserviceForm({
                                                    ...serviceForm, session_hours: e.target.value,
                                                });
                                            }}>
                                                <option value="0">00</option>
                                                <option value="1">01</option>
                                                <option value="2">02</option>
                                                <option value="3">03</option>
                                                <option value="4">04</option>
                                                <option value="5">05</option>
                                                <option value="6">06</option>
                                                <option value="7">07</option>
                                                <option value="8">08</option>
                                                <option value="9">09</option>
                                                <option value="10">10</option>
                                                <option value="11">11</option>
                                                <option value="12">12</option>
                                                <option value="13">13</option>
                                                <option value="14">14</option>
                                                <option value="15">15</option>
                                                <option value="16">16</option>
                                                <option value="17">17</option>
                                                <option value="18">18</option>
                                                <option value="19">19</option>
                                                <option value="20">20</option>
                                                <option value="21">21</option>
                                                <option value="22">22</option>
                                                <option value="23">23</option>
                                            </select>
                                        </div>
                                        {isError && <p className="t-12-bold text-danger">{isError}</p>}
                                        {/*</div>*/}

                                    </div>
                                    <div className="col-12 col-sm-6 mt-3">
                                        <label htmlFor=""> Session Minutes</label>
                                        {/*<div className="time-picker  mt-2">*/}
                                        {/*    <LocalizationProvider dateAdapter={AdapterDayjs} sx={{*/}
                                        {/*        backgroundColor: "white",*/}
                                        {/*        paddingTop: "0 !important"*/}
                                        {/*    }}>*/}
                                        {/*        <DemoContainer*/}
                                        {/*            components={['MobileTimePicker', 'MobileTimePicker', 'MobileTimePicker']}*/}
                                        {/*        >*/}
                                        {/*            <DemoItem sx={{backgroundColor: "white !important"}}>*/}
                                        {/*                <TimePicker required views={['minutes']}*/}
                                        {/*                            value={dayjs(`2022-04-17T15:${serviceForm.session_minutes}`)}*/}

                                        {/*                            sx={{*/}
                                        {/*                                backgroundColor: "white",*/}
                                        {/*                                borderRadius: "5px",*/}
                                        {/*                                outline: "none"*/}
                                        {/*                            }} format="mm" onChange={(newValue) => {*/}
                                        {/*                    setserviceForm({*/}
                                        {/*                        ...serviceForm, session_minutes: newValue?.$m,*/}
                                        {/*                    });*/}
                                        {/*                }}/>*/}
                                        {/*            </DemoItem>*/}
                                        {/*        </DemoContainer>*/}
                                        {/*    </LocalizationProvider>*/}
                                        {/*</div>*/}
                                        <div className="group-input mt-2 p-15 clearfix">
                                            <select name="minit" id="minit" style={{fontSize: "15px"}} required
                                                    value={serviceForm.session_minutes} onChange={(e) => {
                                                setserviceForm({
                                                    ...serviceForm, session_minutes: e.target.value,
                                                });
                                            }}>
                                                {minitObjects?.map((ele) => (<option style={{marginTop: '5px'}}
                                                                                     value={ele.value}>{ele.label}</option>))}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-6 mt-3">
                                        <label htmlFor="">Session Price</label>
                                        <div className="group-input mt-2 p-15 clearfix">
                                            <input
                                                type="number"
                                                name=""
                                                max={100000}
                                                min="1"
                                                value={serviceForm.session_price}
                                                onChange={(e) => {
                                                    if (e.target.value.length <= 6) {
                                                        setserviceForm({
                                                            ...serviceForm, session_price: e.target.value,
                                                        });
                                                    }
                                                }}
                                                placeholder="Enter Amount"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 mt-3">
                                        <label htmlFor="">Participants</label>
                                        <div className="group-input mt-2 p-15 clearfix d-flex">
                                            <input
                                                type="text"
                                                name=""
                                                value="1 -"
                                                readOnly
                                                className="showArrow"
                                                style={{
                                                    width: "40px",
                                                    padding: "15px 0 15px 20px",
                                                    borderRadius: "3px 0 0 3px",
                                                }}
                                            />
                                            <input
                                                type="number"
                                                name=""
                                                required
                                                max={50}
                                                min="1"
                                                className="showArrow"
                                                value={serviceForm.member}
                                                onInput="validity.valid||(value='');"
                                                onChange={(e) => {


                                                    if (e.target.value >= 0) {
                                                        if (e.target.value.length <= 3) {
                                                            setserviceForm({
                                                                ...serviceForm, member: e.target.value,
                                                            });
                                                        }
                                                    }

                                                }}
                                                placeholder="2"
                                                style={{
                                                    borderRadius: "0 3px 3px 0", paddingLeft: "0",
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 mt-3">
                                        <label htmlFor="">Price / Additional Participants</label>
                                        <div className="group-input mt-2 p-15 clearfix">
                                            <input
                                                type="number"
                                                name=""
                                                required
                                                max={100000}
                                                min={1}
                                                value={serviceForm.addition_price}
                                                onChange={(e) => {
                                                    if (e.target.value > 0 || e.target.value == "") {
                                                        if (e.target.value.length <= 6) {
                                                            setserviceForm({
                                                                ...serviceForm, addition_price: e.target.value,
                                                            });
                                                        }
                                                    }

                                                }}
                                                placeholder="Enter Participate"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 mt-3" style={{
                                        display: "flex", flexDirection: "column", justifyContent: "space-between"
                                    }}>
                                        <label htmlFor="">Maximum Participants</label>
                                        <div className="group-input mt-2 p-15 clearfix">
                                            <input
                                                type="number"
                                                name=""
                                                required
                                                min={1}
                                                max={10000}
                                                className="showArrow"
                                                value={serviceForm.meximum_member}
                                                onChange={(e) => {
                                                    if (e.target.value > 0 || e.target.value == "") {
                                                        if (e.target.value.length <= 3) {
                                                            setserviceForm({
                                                                ...serviceForm, meximum_member: e.target.value,
                                                            });
                                                        }
                                                    }

                                                }}
                                                placeholder="15"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="group-input mt-3 p-15 clearfix ">
                            <textarea
                                name=""
                                value={serviceForm.discription}
                                onChange={(e) => {
                                    if (e.target.value.length <= 350) {
                                        setserviceForm({
                                            ...serviceForm, discription: e.target.value,
                                        });
                                    }
                                }}
                                style={{height: "130px"}}
                                placeholder="Describe your services (optional)"
                                rows={3}
                            />
                            </div>
                        </div>
                    </div>
                    <div className="btn_gred mt-4">
                        {/*<button*/}
                        {/*    type="submit"*/}
                        {/*    className="btn_admina"*/}
                        {/*>*/}
                        {/*    Save*/}
                        {/*</button>*/}
                        {loader ? (<a
                            href="#"
                            onClick={(e) => e.preventDefault()}
                            className="btn_admina "
                        >
                            {/* save */}
                            <div className="loader"></div>
                        </a>) : (<button type="submit" className="btn_admina ">
                            save
                        </button>)}
                    </div>
                </form>
            </div>
        </Modal>
    </>)
}

export default AddServicePopup;