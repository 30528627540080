// project import
import Routes from 'routes';
import ThemeCustomization from 'themes';
import ScrollTop from 'components/ScrollTop';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './assets/css/style.css';
import { createContext, useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'react-multi-carousel/lib/styles.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment-timezone';

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //
export const DomainContext = createContext();
export const PadinationContext = createContext();

const App = () => {
    const [pagination, setpagination] = useState({
        category: 1,
        users: 1,
        provider: 1,
        events: 1,
        transactions: 1,
        shippingProduct: 1
    });

    return (
        <>
            <PadinationContext.Provider value={{ pagination, setpagination }}>
                <ThemeCustomization>
                    <ScrollTop>
                        <Routes />
                    </ScrollTop>
                </ThemeCustomization>
            </PadinationContext.Provider>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                pauseOnHover
            />
        </>
    );
};

export default App;
