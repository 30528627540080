import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';

import ClaimUser from '../pages/extra-pages/ClaimUser';

import EditClaimUser from '../pages/extra-pages/EditClaimUser/EditClaimUser';
import Rolepage from 'pages/extra-pages/Roles/Rolepage';
import AddMember from 'pages/extra-pages/Roles/AddMember';

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));

const Users = Loadable(lazy(() => import('pages/extra-pages/Users')));
// const Categories = Loadable(lazy(() => import('pages/extra-pages/Categories')));
const Providers = Loadable(lazy(() => import('pages/extra-pages/Providers')));
const Event = Loadable(lazy(() => import('pages/extra-pages/Event')));
const Services = Loadable(lazy(() => import('pages/extra-pages/Services')));
const Profile = Loadable(lazy(() => import('pages/extra-pages/Profile')));
const Transaction = Loadable(lazy(() => import('pages/extra-pages/Transaction')));
const BannerImage = Loadable(lazy(() => import('pages/extra-pages/BannerImage/BannerImage')));
const ProviderProfile = Loadable(lazy(() => import('pages/extra-pages/ProviderDetails')));
const TransactionView = Loadable(lazy(() => import('pages/extra-pages/TransactionView')));
const BannerImageView = Loadable(lazy(() => import('pages/extra-pages/BannerImage/BannerImageView')));
const ClaimUserView = Loadable(lazy(() => import('pages/extra-pages/ClaimUserView')));
const SalesTax = Loadable(lazy(() => import('pages/extra-pages/SalesTax')));
const Support = Loadable(lazy(() => import('pages/extra-pages/Support/Support')));
const ReferralCodes = Loadable(lazy(() => import('pages/extra-pages/ReferralCodes')));
const GenerateCode = Loadable(lazy(() => import('pages/extra-pages/ReferralCodes/GenerateCode/GenerateCode')));
const DiscountCode = Loadable(lazy(() => import('pages/extra-pages/Discount')));
const ReferralCodeUsers = Loadable(lazy(() => import('pages/extra-pages/ReferralCodes/ReferralCodeUsers')));
const GenerateDiscountCode = Loadable(lazy(() => import('pages/extra-pages/Discount/GenerateDiscountCode/GenerateDiscountCode')));
const DiscountCodeUser = Loadable(lazy(() => import('pages/extra-pages/Discount/DiscountCodeUser')));
const PrePopEvent = Loadable(lazy(() => import('pages/extra-pages/PrePopulateEvent')));
const CreateEvent = Loadable(lazy(() => import('pages/extra-pages/PrePopulateEvent/CreateEvent')));
const ViewEvent = Loadable(lazy(() => import('pages/extra-pages/PrePopulateEvent/EventDetails')));
const Reports = Loadable(lazy(() => import('pages/extra-pages/Report/Reports')));
const Notfound = Loadable(lazy(() => import('pages/Notfound')));
// // ==============================|| MAIN ROUTING ||============================== //
// const BlankScreen = () => <div style={{display:"flex" ,justifyContent:"center",alignItems:"center" }}><img src={nopage}/></div>; // Blank screen

// const MainRoutes = {
//     path: '/',
//     element: <MainLayout />,
//     children:[]
//     children: [
//         {
//             path: '/',
//             element: <DashboardDefault />
//         },
//         {
//             path: 'dashboard',
//             element: <DashboardDefault />
//         },
//         {
//             path: 'users',
//             element: <Users />
//         },
//         {
//             path: 'users/:number',
//             element: <Users />
//         },
//         // {
//         //     path: 'categories',
//         //     element: <Categories />
//         // },
//         {
//             path: 'provider',
//             element: <Providers />
//         },
//         {
//             path: 'provider/:number',
//             element: <Providers />
//         },
//         {
//             path: 'events',
//             element: <Event />
//         },
//         {
//             path: 'events/:number',
//             element: <Event />
//         },
//         {
//             path: 'services/:number',
//             element: <Services />
//         },
//         {
//             path: 'services',
//             element: <Services />
//         },
//         {
//             path: 'transaction',
//             element: <Transaction />
//         },

//         {
//             path: 'profile/:profile_id',
//             element: <Profile />
//         },
//         ,  { path: 'not-found', element: <Notfound />},

//         {
//             path: 'providerdetails/:profile_id',
//             element: <ProviderProfile />
//         },
//         {
//             path: 'sales-tax',
//             element: <SalesTax />
//         },
//         {
//             path: 'sales-tax/:number',
//             element: <SalesTax />
//         },
//         {
//             path: 'pre-polutation',
//             element: <ClaimUser />
//         },
//         {
//             path: 'pre-polutation/:number',
//             element: <ClaimUser />
//         },
//         {
//             path: 'pre-polutation-user/:id',
//             element: <ClaimUserView />
//         },
//         {
//             path: 'edit-pre-polutation/:id',
//             element: <EditClaimUser />
//         },
//         {
//             path: 'banner-image',
//             element: <BannerImage />
//         },
//         {
//             path: 'banner-image/:id',
//             element: <BannerImageView />
//         },
//         {
//             path: 'transactions',
//             element: <Transaction />
//         },
//         {
//             path: 'transactions/:number',
//             element: <Transaction />
//         },
//         {
//             path: 'transactions-details/:id',
//             element: <TransactionView />
//         },
//         {
//             path: 'support',
//             element: <Support />
//         },
//         {
//             path: 'support/:number',
//             element: <Support />
//         },
//         {
//             path: 'referral-codes',
//             element: <ReferralCodes />
//         },
//         {
//             path: 'referral-codes/:number',
//             element: <ReferralCodes />
//         },
//         {
//             path: 'generate-code',
//             element: <GenerateCode />
//         },
//         {
//             path: 'edit-code/:id',
//             element: <GenerateCode />
//         },
//         {
//             path: 'referral-codes-details/:id',
//             element: <ReferralCodeUsers />
//         },
//         {
//             path: 'referral-codes-details/:id/:number',
//             element: <ReferralCodeUsers />
//         },
//         {
//             path: 'discount-codes',
//             element: <DiscountCode />
//         },
//         {
//             path: 'discount-codes/:number',
//             element: <DiscountCode />
//         },
//         {
//             path: 'discount-codes-user/:id',
//             element: <DiscountCodeUser />
//         },
//         {
//             path: 'discount-codes-user/:id/:number',
//             element: <DiscountCodeUser />
//         },
//         {
//             path: 'generate-discount-codes',
//             element: <GenerateDiscountCode />
//         },
//         {
//             path: 'edit-discount-code/:id',
//             element: <GenerateDiscountCode />
//         },
//         {
//             path: 'pre-pop-events/:number',
//             element: <PrePopEvent />
//         },
//         {
//             path: 'pre-pop-events',
//             element: <PrePopEvent />
//         },
//         {
//             path: 'create-event',
//             element: <CreateEvent />
//         },
//         {
//             path: 'create-event/:id',
//             element: <CreateEvent />
//         },
//         {
//             path: 'view-events/:id/:providerId',
//             element: <ViewEvent />
//         },
//         {
//             path: 'reports',
//             element: <Reports />
//         },
//         {
//             path: 'reports/:number',
//             element: <Reports />
//         },
//         {
//             path:'/roles',
//             element: <Rolepage />
//         },
//         {
//             path:'/roles/:number',
//             element: <Rolepage />
//         }
//         ,{
//             path:'/addmembers',
//             element: <AddMember />
//         }
//         ,{
//             path:'/addmembers/:id',
//             element: <AddMember />
//         }
//         {path:"*",
//             element:<BlankScreen />
//         }
//     ]
// };
const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [     ]
};
const accessManagement = () => {
    const userData = localStorage.getItem('adAnimaLogin');
    if (userData) {
        if (JSON.parse(userData).user_type == 'sub_admin') {
            const userRole = JSON.parse(userData).roles;

            MainRoutes.children = MainRoutes.children || [];

            if (userRole.dashboard) {
                MainRoutes.children.push(
                    {
                        path: '/',
                        element: <DashboardDefault />
                    },
                    {
                        path: 'dashboard',
                        element: <DashboardDefault />
                    }
                );
            }
            if (userRole.users) {
                MainRoutes.children.push(
                    {
                        path: 'users',
                        element: <Users />
                    },
                    {
                        path: 'users/:number',
                        element: <Users />
                    },
                    {
                        path: 'providerdetails/:profile_id',
                        element: <ProviderProfile />
                    }
                );
            }
            if (userRole.events) {
                MainRoutes.children.push(
                    {
                        path: 'events',
                        element: <Event />
                    },
                    {
                        path: 'events/:number',
                        element: <Event />
                    },
                    {
                        path: 'view-events/:id/:providerId',
                        element: <ViewEvent />
                    }
                );
            }
            if (userRole.providers) {
                MainRoutes.children.push(
                    {
                        path: 'provider',
                        element: <Providers />
                    },
                    {
                        path: 'provider/:number',
                        element: <Providers />
                    },
                    {
                        path: 'providerdetails/:profile_id',
                        element: <ProviderProfile />
                    }
                );
            }
            if (userRole.discount_codes) {
                MainRoutes.children.push(
                    {
                        path: 'discount-codes',
                        element: <DiscountCode />
                    },
                    {
                        path: 'discount-codes/:number',
                        element: <DiscountCode />
                    },
                    {
                        path: 'discount-codes-user/:id',
                        element: <DiscountCodeUser />
                    },
                    {
                        path: 'discount-codes-user/:id/:number',
                        element: <DiscountCodeUser />
                    },
                    {
                        path: 'generate-discount-codes',
                        element: <GenerateDiscountCode />
                    },
                    {
                        path: 'edit-discount-code/:id',
                        element: <GenerateDiscountCode />
                    }
                );
            }
            if (userRole.banner_image) {
                MainRoutes.children.push(
                    {
                        path: 'banner-image',
                        element: <BannerImage />
                    },
                    {
                        path: 'banner-image/:id',
                        element: <BannerImageView />
                    }
                );
            }
            if (userRole.pre_population) {
                MainRoutes.children.push(
                    {
                        path: 'pre-polutation',
                        element: <ClaimUser />
                    },
                    {
                        path: 'pre-polutation/:number',
                        element: <ClaimUser />
                    },
                    {
                        path: 'pre-polutation-user/:id',
                        element: <ClaimUserView />
                    },
                    {
                        path: 'edit-pre-polutation/:id',
                        element: <EditClaimUser />
                    }
                );
            }
            if (userRole.pre_population_events) {
                MainRoutes.children.push(
                    {
                        path: 'pre-pop-events/:number',
                        element: <PrePopEvent />
                    },
                    {
                        path: 'pre-pop-events',
                        element: <PrePopEvent />
                    },
                    {
                        path: 'create-event',
                        element: <CreateEvent />
                    },
                    {
                        path: 'create-event/:id',
                        element: <CreateEvent />
                    },
                    {
                        path: 'view-events/:id/:providerId',
                        element: <ViewEvent />
                    }
                );
            }
            if (userRole.referral_codes) {
                MainRoutes.children.push(
                    {
                        path: 'referral-codes',
                        element: <ReferralCodes />
                    },
                    {
                        path: 'referral-codes/:number',
                        element: <ReferralCodes />
                    },
                    {
                        path: 'referral-codes-details/:id',
                        element: <ReferralCodeUsers />
                    },
                    {
                        path: 'referral-codes-details/:id/:number',
                        element: <ReferralCodeUsers />
                    },
                    {
                        path: 'edit-code/:id',
                        element: <GenerateCode />
                    }
                );
            }
            if (userRole.report) {
                MainRoutes.children.push(
                    {
                        path: 'reports',
                        element: <Reports />
                    },
                    {
                        path: 'reports/:number',
                        element: <Reports />
                    }
                );
            }
            if (userRole.sales_tax) {
                MainRoutes.children.push(
                    {
                        path: 'sales-tax',
                        element: <SalesTax />
                    },
                    {
                        path: 'sales-tax/:number',
                        element: <SalesTax />
                    }
                );
            }
            if (userRole.services) {
                MainRoutes.children.push(
                    {
                        path: 'services/:number',
                        element: <Services />
                    },
                    {
                        path: 'services',
                        element: <Services />
                    },
                    {
                        path: 'providerdetails/:profile_id',
                        element: <ProviderProfile />
                    }
                );
            }
            if (userRole.support) {
                MainRoutes.children.push(
                    {
                        path: 'support',
                        element: <Support />
                    },
                    {
                        path: 'support/:number',
                        element: <Support />
                    }
                );
            }
            if (userRole.transactions) {
                MainRoutes.children.push(
                    {
                        path: 'transactions',
                        element: <Transaction />
                    },
                    {
                        path: 'transactions/:number',
                        element: <Transaction />
                    },
                    {
                        path: 'transactions-details/:id',
                        element: <TransactionView />
                    }
                );
            }
            MainRoutes.children.push(
                {
                    path: '/roles',
                    element: <Rolepage />
                },
                {
                    path: '/roles/:number',
                    element: <Rolepage />
                },
                {
                    path: '/addmembers',
                    element: <AddMember />
                },
                {
                    path: '/addmembers/:id',
                    element: <AddMember />
                },
                // {path:'not-found',element: <Notfound />},
            );
          
        } else if (JSON.parse(userData).user_type == 'admin') {
            MainRoutes.children.push(
                {
                    path: '/',
                    element: <DashboardDefault />
                },
                {
                    path: 'dashboard',
                    element: <DashboardDefault />
                },
                {
                    path: 'users',
                    element: <Users />
                },
                {
                    path: 'users/:number',
                    element: <Users />
                },
                // {path:'not-found',element: <Notfound />},
                // {
                //     path: 'categories',
                //     element: <Categories />
                // },
                {
                    path: 'provider',
                    element: <Providers />
                },
                {
                    path: 'provider/:number',
                    element: <Providers />
                },
                {
                    path: 'events',
                    element: <Event />
                },
                {
                    path: 'events/:number',
                    element: <Event />
                },
                {
                    path: 'services/:number',
                    element: <Services />
                },
                {
                    path: 'services',
                    element: <Services />
                },
                {
                    path: 'transaction',
                    element: <Transaction />
                },

                {
                    path: 'profile/:profile_id',
                    element: <Profile />
                },
          

                {
                    path: 'providerdetails/:profile_id',
                    element: <ProviderProfile />
                },
                {
                    path: 'sales-tax',
                    element: <SalesTax />
                },
                {
                    path: 'sales-tax/:number',
                    element: <SalesTax />
                },
                {
                    path: 'pre-polutation',
                    element: <ClaimUser />
                },
                {
                    path: 'pre-polutation/:number',
                    element: <ClaimUser />
                },
                {
                    path: 'pre-polutation-user/:id',
                    element: <ClaimUserView />
                },
                {
                    path: 'edit-pre-polutation/:id',
                    element: <EditClaimUser />
                },
                {
                    path: 'banner-image',
                    element: <BannerImage />
                },
                {
                    path: 'banner-image/:id',
                    element: <BannerImageView />
                },
                {
                    path: 'transactions',
                    element: <Transaction />
                },
                {
                    path: 'transactions/:number',
                    element: <Transaction />
                },
                {
                    path: 'transactions-details/:id',
                    element: <TransactionView />
                },
                {
                    path: 'support',
                    element: <Support />
                },
                {
                    path: 'support/:number',
                    element: <Support />
                },
                {
                    path: 'referral-codes',
                    element: <ReferralCodes />
                },
                {
                    path: 'referral-codes/:number',
                    element: <ReferralCodes />
                },
                {
                    path: 'generate-code',
                    element: <GenerateCode />
                },
                {
                    path: 'edit-code/:id',
                    element: <GenerateCode />
                },
                {
                    path: 'referral-codes-details/:id',
                    element: <ReferralCodeUsers />
                },
                {
                    path: 'referral-codes-details/:id/:number',
                    element: <ReferralCodeUsers />
                },
                {
                    path: 'discount-codes',
                    element: <DiscountCode />
                },
                {
                    path: 'discount-codes/:number',
                    element: <DiscountCode />
                },
                {
                    path: 'discount-codes-user/:id',
                    element: <DiscountCodeUser />
                },
                {
                    path: 'discount-codes-user/:id/:number',
                    element: <DiscountCodeUser />
                },
                {
                    path: 'generate-discount-codes',
                    element: <GenerateDiscountCode />
                },
                {
                    path: 'edit-discount-code/:id',
                    element: <GenerateDiscountCode />
                },
                {
                    path: 'pre-pop-events/:number',
                    element: <PrePopEvent />
                },
                {
                    path: 'pre-pop-events',
                    element: <PrePopEvent />
                },
                {
                    path: 'create-event',
                    element: <CreateEvent />
                },
                {
                    path: 'create-event/:id',
                    element: <CreateEvent />
                },
                {
                    path: 'view-events/:id/:providerId',
                    element: <ViewEvent />
                },
                {
                    path: 'reports',
                    element: <Reports />
                },
                {
                    path: 'reports/:number',
                    element: <Reports />
                },
                {
                    path: '/roles',
                    element: <Rolepage />
                },
                {
                    path: '/roles/:number',
                    element: <Rolepage />
                },
                {
                    path: '/addmembers',
                    element: <AddMember />
                },
                {
                    path: '/addmembers/:id',
                    element: <AddMember />
                }
            );
        }
    }

};

accessManagement();

export default MainRoutes;
