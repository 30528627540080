// assets
import {
    AntDesignOutlined,
    FontSizeOutlined,
    AppstoreAddOutlined,
    UserOutlined,
    ProfileOutlined,
    ProjectOutlined,
    SwapOutlined,
    DeliveredProcedureOutlined,
    MonitorOutlined,
    PlusOutlined,
    FileImageOutlined,
    SettingOutlined,
    UsergroupAddOutlined,
    RadarChartOutlined,
    TeamOutlined,
    FileTextOutlined 

} from '@ant-design/icons';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import GroupsIcon from '@mui/icons-material/Groups';
import { Link, useLocation } from "react-router-dom";
// icons
const icons = {
    FontSizeOutlined,
    AntDesignOutlined,
    AppstoreAddOutlined,
    UserOutlined,
    ProfileOutlined,
    ProjectOutlined,
    SwapOutlined,
    DeliveredProcedureOutlined,
    MonitorOutlined,
    PlusOutlined,
    SettingOutlined,
    UsergroupAddOutlined,
    FileImageOutlined,
    ConfirmationNumberOutlinedIcon,
    RadarChartOutlined,
    TeamOutlined,
    FileTextOutlined,
    GroupsIcon
};

// ==============================|| MENU ITEMS - UTILITIES ||============================== //

const utilities = {
    id: 'utilities',
    type: 'group',
    children: [
        // {
        //     id: 'util-category',
        //     title: 'Categories',
        //     type: 'item',
        //     forPagination: 'category',
        //     url: '/categories',
        //     icon: icons.AppstoreAddOutlined
        // },
        {
            id: 'util-user',
            title: 'Users',
            type: 'item',
            forPagination: 'users',
            url: '/users',
            icon: icons.UserOutlined,
            subname:'users'
        },
        {
            id: 'util-providers',
            title: 'Providers',
            type: 'item',
            forPagination: 'providers',
            url: '/provider',
            icon: icons.TeamOutlined,
             subname:'providers'    
        },
        {
            id: 'util-event',
            title: 'Events',
            type: 'item',
            forPagination: 'events',
            url: '/events',
            icon: icons.ProjectOutlined,
             subname:'events'
        },
        {
            id: 'util-services',
            title: 'Services',
            type: 'item',
            forPagination: 'services',
            url: '/services',
            icon: icons.RadarChartOutlined,
             subname:'services'
        },
        {
            id: 'util-salesTax',
            title: 'Sales Tax',
            type: 'item',
            forPagination: 'salesTax',
            url: '/sales-tax',
            icon: icons.MonitorOutlined,
             subname:'sales_tax'
        },
        {
            id: 'util-prePolution',
            title: 'Pre-Population',
            type: 'item',
            forPagination: 'prePolution',
            url: '/pre-polutation',
            icon: icons.PlusOutlined,
             subname:'pre_population'
        },
        {
            id: 'util-bannerImage',
            title: 'Banner Image',
            type: 'item',
            forPagination: 'bannerImage',
            url: '/banner-image',
            icon: icons.AppstoreAddOutlined,
             subname:'banner_image'
        },
        {
            id: 'util-transition',
            title: 'Transactions',
            type: 'item',
            forPagination: 'Transactions',
            url: '/transactions',
            icon: icons.SwapOutlined,
             subname:'transactions'
        },
        {
            id: 'util-support',
            title: 'Support',
            type: 'item',
            forPagination: 'Support',
            url: '/support',
            icon: icons.SettingOutlined,
             subname:'support'
        },
        {
            id: 'util-refcode',
            title: 'Referral-Codes',
            type: 'item',
            forPagination: 'Referral-Codes',
            url: '/referral-codes',
            icon: icons.UsergroupAddOutlined,
             subname:'referral_codes'
        },
        {
            id: 'util-discount',
            title: 'Discount-Codes',
            type: 'item',
            forPagination: 'Discount-Codes',
            url: '/discount-codes',
            icon: icons.ConfirmationNumberOutlinedIcon,
             subname:'discount_codes'
        },
        {
            id: 'util-prePopEvent',
            title: 'Pre-Polutation Events',
            type: 'item',
            forPagination: 'PRE-POLUTATION EVENTS',
            url: '/pre-pop-events',
            icon: icons.PlusOutlined,
             subname:'pre_population_events'
        },
        {
            id: 'util-reports',
            title: 'Reports',
            type: 'item',
            forPagination: 'REPORTS',
            url: '/reports',
            icon: icons.FileTextOutlined,
             subname:'report'
        },
        {
            id: 'util-roles',
            title: 'Roles',
            type: 'item',
            forPagination: 'Roles',
            url: '/roles',
            icon: GroupsIcon ,

        },
        // {
        //     id: 'util-transaction',
        //     title: 'Transaction',
        //     type: 'item',
        //     forPagination: 'transactions',
        //     url: '/transaction',
        //     icon: icons.SwapOutlined
        // }
        // {
        //     id: 'util-shippingproduct',
        //     title: 'Shipping Products',
        //     type: 'item',
        //     forPagination: 'shippingProduct',
        //     url: '/shippingproduct',
        //     icon: icons.DeliveredProcedureOutlined
        // }
    ]
};

export default utilities;
