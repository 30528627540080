// material-ui
import { Box, Typography } from '@mui/material';

// project import
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import { useNavigate } from '../../../../../../node_modules/react-router-dom/dist/index';
import { useEffect, useState } from 'react';
import { BASE_URL } from 'Configration';
import { dispatch } from 'store/index';
import { activeItem } from 'store/reducers/menu';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //
 
    
const Navigation = () => {
    const token = JSON.parse(localStorage.getItem('adAnimaLogin'));
    console.log(token);
    
    const [datavalue,setdata]=useState([]);
    console.log(datavalue,setdata);


    
    const navigate =useNavigate();
    useEffect(()=>{
        // dispatch(activeItem({ openItem: ['dashboard'] }));
        if (token?.is_login && token?.is_login == true) {
       try {
           fetch(`${BASE_URL}/admin/orange_flag`, {
               method: 'POST',
               headers: {
                   Authorization: `Bearer ${token.token}`
               },
               body: ''
           })
               .then((response) => response.json())
               .then((data) => {
                   if (data.success) {
                       setdata(data.data);
                   } else if (data.message == 'Authentication failed.') {
                       navigate('/login');
                   }
               });
       } catch (err) {
        console.log(err);
    }
} else {
    navigate('/login');
}

   } ,[])
    const navGroups = menuItem.items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} dataitem={datavalue} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Fix - Navigation Group
                    </Typography>
                );
        }
    });

    return <Box sx={{ pt: 2 }}>{navGroups}</Box>;
};

export default Navigation;
